import React, { useEffect, useState, useRef } from 'react';
import styles from './ArtistDashboard.module.css';
import { getObfuscatedKeyWithTimestamp } from '../../services/policy_delta';
import { House, Share, MapPin, Users, Map, MoreHorizontal, ChevronDown } from 'lucide-react';
import axios from 'axios';


function ArtistDashboard({ artistName, onBackToSearch, onShowVenueGraph, onAudienceFinder }) {
  const [artistData, setArtistData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedSection, setExpandedSection] = useState(null);
  const [leftPanelExpanded, setLeftPanelExpanded] = useState(false);
  const [scrollArrowVisible, setScrollArrowVisible] = useState(true);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const rightContentRef = useRef(null);
  const [dragStartY, setDragStartY] = useState(0);
  const [isDragging, setIsDragging] = useState(false);



  useEffect(() => {
    const fetchArtistData = async () => {
      try {
        const { obfuscatedKey, timestamp } = getObfuscatedKeyWithTimestamp();
        const response = await axios.get(`${process.env.REACT_APP_API}/artist/profile?artist_name=${encodeURIComponent(artistName)}`, {
          headers: {
            'API-Key': obfuscatedKey,
            'timestamp': timestamp,
          }
        });
        const data = response.data;
        data.name = data.name.charAt(0).toUpperCase() + data.name.slice(1);
        setArtistData(data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching artist data:', error);
        setIsLoading(false);
      }
    };
    fetchArtistData();
  }, [artistName]);

  useEffect(() => {
    const handleScroll = () => {
      if (rightContentRef.current && !hasScrolled) {
        const scrollPosition = rightContentRef.current.scrollTop;
        if (scrollPosition > 0) {
          setScrollArrowVisible(false);
          setHasScrolled(true);
        }
      }
    };

    const rightContent = rightContentRef.current;
    if (rightContent) {
      rightContent.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (rightContent) {
        rightContent.removeEventListener('scroll', handleScroll);
      }
    };
  }, [hasScrolled]);

  if (isLoading) {
    return (
      <div className='content'>
        <div className={styles.loadingSpinner}>
          <div className={styles.spinner}></div>
          <p>Loading artist data...</p>
        </div>
      </div>
    );
  }

  if (!artistData || !artistData.name) {
    setTimeout(() => {
      onBackToSearch();
    }, 3000);
    
    return (
        <div className='content'>
        <div className={styles.notFoundOverlay}>
          <div className={styles.notFoundContent}>
            <div className={styles.notFoundIcon}>🎵</div>
            <h2>Artist Not Found</h2>
            <p>We couldn't find any data for this artist</p>
            <div className={styles.redirectMessage}>
              Redirecting back to search...
              <div className={styles.loadingDots}>
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </div>
            </div>
          </div>
        </div>
        </div>
      
      );
  }

  const handleButtonClick = (event) => {
    event.stopPropagation();
  };

  const handleShare = () => {
    if (navigator.share) {
      const url = new URL(window.location.href);
      url.search = '';  
      const cleanUrl = url.toString();
      navigator.share({
        title: `I found an artist for you!`,
        text: `Check out ${artistData.name} on the Synspire!`,
        url: `${cleanUrl}?artist_name=${encodeURIComponent(artistData.name)}`,
      }).catch((error) => console.error('Error sharing:', error));
    } else {
      console.warn('Web Share API not supported in this browser.');
    }
  };

  const toggleExpand = (section) => {
    setExpandedSection(prevSection => prevSection === section ? null : section);
  };

  const truncateDescription = (text, maxLength = 500) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      toggleExpand(null);
    }
  };

  const handlePanelTouchStart = (e) => {
    setDragStartY(e.touches[0].clientY);
    setIsDragging(true);
  };

  const handlePanelTouchMove = (e) => {
    if (!isDragging) return;
    const touchY = e.touches[0].clientY;
    if (dragStartY - touchY > 50) {
      setLeftPanelExpanded(true);
      setIsDragging(false);
    }
  };

  const handlePanelTouchEnd = () => {
    setIsDragging(false);
  };

  const ExpandableSection = ({ title, content }) => (
    <div className={styles.expandableSection}>
      <h2>{title}</h2>
      <div className={styles.expandableContent}>{content}</div>
    </div>
  );

  return (
    <div className={styles.fullScreenContainer}>
      <div className={styles.dashboard}>
        <div 
        className={`${styles.leftPanel} ${leftPanelExpanded ? styles.expanded : ''}`}
        onClick={() => setLeftPanelExpanded(!leftPanelExpanded)}
        onTouchStart={handlePanelTouchStart}
        onTouchMove={handlePanelTouchMove}
        onTouchEnd={handlePanelTouchEnd}
                
        >
          <div className={`${styles.topButtons} ${leftPanelExpanded ? styles.hidden : ''}`}>
            <button className={styles.topButton} onClick={(event) => { onAudienceFinder(); handleButtonClick(event); }}>
              <Users size={20} />
            </button>
            <button className={styles.topButton} onClick={(event) => { onShowVenueGraph(); handleButtonClick(event); }}>
              <Map size={20} />
            </button>
          </div>
          <div className={styles.leftPanelIndicator}>
            <MoreHorizontal size={24} />
          </div>

          <h1>{artistData.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</h1>
          <p><MapPin size={16}/>  {Array.isArray(artistData.location) ? artistData.location.join(', ') : artistData.location}</p>
          
          <p onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}className={isDescriptionExpanded ? styles.expandedBio : styles.truncatedBio}>
              {isDescriptionExpanded ? artistData.bio : truncateDescription(artistData.bio)}
          </p>


          <h2>Genres</h2>  
          <div className={styles.tagContainer}>
            {artistData.genre.map((genre, index) => (
              <span key={index} className={styles.tag}>{genre}</span>
            ))}
          </div>

          <h2>Similar Artists</h2>
          <div className={styles.tagContainer}>
            {artistData.influences.map((influence, index) => (
              <span key={index} className={styles.tag}>{influence}</span>
            ))}
          </div>

          <div className={styles.buttonContainer}>
            <button onClick={handleShare}><Share size={16} /> Share Profile</button>
            <button onClick={onShowVenueGraph}><Map size={16} /> Venue Finder</button>
            <button onClick={onAudienceFinder}><Users size={16} /> Discover Audiences</button>
            <button onClick={onBackToSearch}><House size={16} /> Home</button>
          </div>
        </div>
        <div className={`${styles.rightContent} ${leftPanelExpanded ? styles.leftPanelExpanded : ''}`}>
          <div className={styles.topRow}>
            <div className={styles.uspRow}>
            <ExpandableSection
                title="Unique Selling Points"
                content={
                  <ul>
                    {Object.entries(artistData.usp).map(([key, value]) => (
                      <li key={key}>
                        <strong>{key}:</strong> {value}
                      </li>
                    ))}
                  </ul>
                }
              />
              {scrollArrowVisible && (
                <div className={styles.scrollArrow}>
                  <ChevronDown size={24} />
                </div>
              )}
            </div>
            <div className={styles.keyTalentsRow}>
              <ExpandableSection
                title="Key Talents"
                content={
                  <ul>
                    {Object.entries(artistData.key_talents).map(([key, value]) => (
                      <li key={key}>
                        <strong>{key}:</strong> {value}
                      </li>
                    ))}
                  </ul>
                }
              />
            </div>
          </div>
          <div className={styles.middleRow}>
            <h2>Core Values</h2>
            <div className={styles.coreValuesGrid}>
              {Object.entries(artistData.core_values).map(([key, value], index) => (
                <div key={index} className={styles.card}>
                  <h3>{key}</h3>
                  <p>{value.charAt(0).toUpperCase() + value.slice(1)}</p>
                </div>
              ))}
            </div>
          </div>

          <div className={styles.bottomRow}>
            <h2>Current Audience</h2>
            <div className={styles.scrollableCards}>
              {artistData.target_audience.map((audience, index) => (
                <div key={index} className={styles.card}>
                  <h3>{audience.audience}</h3>
                  <p>Age: {audience.age}</p>
                  <p><strong>Interests:</strong> {audience.interest.join(', ')}</p>
                  <p><strong>Values:</strong> {audience.values.join(', ')}</p>
                </div>
              ))}
            </div>

        {expandedSection && (
          <div className={styles.overlay} onClick={handleOverlayClick}>
            <div className={styles.overlayContent} onClick={(e) => e.stopPropagation()}>
              <h2>{expandedSection}</h2>
              {expandedSection === 'Unique Selling Points' ? (
                <ul>
                  {Object.entries(artistData.usp).map(([key, value]) => (
                    <li key={key}>
                      <strong>{key}:</strong> {value}
                    </li>
                  ))}
                </ul>
              ) : (
                <ul>
                  {Object.entries(artistData.key_talents).map(([key, value]) => (
                    <li key={key}>
                      <strong>{key}:</strong> {value}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        )}

          </div>
          
        </div>
        <p className={styles.warning}>Disclaimer: This website is in construction. Please bare with us! </p>
      </div>
    </div>
  );
}

export default ArtistDashboard;