import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import './IntroAnimation.css';

const COLORS = {
  nodes: [
    { color: 'rgba(255,99,71,0.9)', glow: 'rgba(255,99,71,0.4)' },    // Tomato
    { color: 'rgba(46,204,113,0.9)', glow: 'rgba(46,204,113,0.4)' },  // Emerald
    { color: 'rgba(155,89,182,0.9)', glow: 'rgba(155,89,182,0.4)' },  // Amethyst
    { color: 'rgba(52,152,219,0.9)', glow: 'rgba(52,152,219,0.4)' },  // Blue
    { color: 'rgba(241,196,15,0.9)', glow: 'rgba(241,196,15,0.4)' },  // Yellow
    { color: 'rgba(230,126,34,0.9)', glow: 'rgba(230,126,34,0.4)' },  // Orange
    { color: 'rgba(231,76,60,0.9)', glow: 'rgba(231,76,60,0.4)' },    // Red
    { color: 'rgba(22,160,133,0.9)', glow: 'rgba(22,160,133,0.4)' }   // Teal
  ],
  edges: {
    primary: 'rgba(255,255,255,0.15)',
    glow: 'rgba(255,255,255,0.05)'
  }
};

const NetworkNode = ({ position, colorSet, delay, size, visible, isActive }) => (
  <div 
    className={`network-node ${visible ? 'visible' : ''} ${isActive ? 'active' : ''}`}
    style={{
      position: 'absolute',
      left: `${position.x}px`,
      top: `${position.y}px`,
      '--color': colorSet.color,
      '--glow-color': colorSet.glow,
      '--delay': `${delay}s`,
      '--size': `${size}px`
    }}
  >
    <div className="node-core">
      <div className="node-pulse" />
      <div className="node-halo" />
      <div className="node-glow" />
    </div>
  </div>
);

const NetworkEdge = ({ start, end, colorSet, delay, visible }) => {
  const dx = end.x - start.x;
  const dy = end.y - start.y;
  const angle = Math.atan2(dy, dx) * 180 / Math.PI;
  const length = Math.sqrt(dx * dx + dy * dy);

  return (
    <div 
      className={`network-edge ${visible ? 'visible' : ''}`}
      style={{
        position: 'absolute',
        left: `${start.x}px`,
        top: `${start.y}px`,
        width: `${length}px`,
        transform: `rotate(${angle}deg)`,
        '--color': colorSet.color,
        '--glow-color': colorSet.glow,
        '--delay': `${delay}s`
      }}
    >
      <div className="edge-line" />
    </div>
  );
};

const IntroAnimation = ({ onComplete }) => {
  const audioCtx = useMemo(() => new (window.AudioContext || window.webkitAudioContext)(), []);
  const [phase, setPhase] = useState('initial');
  const [activeNodes, setActiveNodes] = useState(new Set());
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });


  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const generateNodes = useCallback(() => {
    const { width, height } = dimensions;
    const nodes = [];
    

    const centerX = width / 2;
    const centerY = height / 2;
    const maxRadius = Math.min(width, height) * 0.65;
    

    const scaleFactor = Math.min(width, height) / 1000;
    const baseSizes = {
      center: Math.max(80 * scaleFactor, 20),
      inner: Math.max(70 * scaleFactor, 15),
      middle: Math.max(60 * scaleFactor, 12),
      outer: Math.max(50 * scaleFactor, 10)
    };


    nodes.push({
      id: 'center',
      position: {
        x: centerX,
        y: centerY
      },
      colorSet: COLORS.nodes[0],
      size: baseSizes.center,
      delay: 0
    });


    const ringConfigs = [
      { count: 6, radiusFactor: 0.3, size: baseSizes.inner },
      { count: 12, radiusFactor: 0.6, size: baseSizes.middle },
      { count: 18, radiusFactor: 0.9, size: baseSizes.outer }
    ];

    ringConfigs.forEach((ring, ringIndex) => {
      const baseRadius = maxRadius * ring.radiusFactor;
      
      for (let i = 0; i < ring.count; i++) {
        const angle = (i / ring.count) * Math.PI * 2;
        // Add some controlled randomness to the radius
        const radiusVariance = (Math.random() - 0.5) * (maxRadius * 0.1);
        const finalRadius = baseRadius + radiusVariance;
        
        const x = centerX + Math.cos(angle) * finalRadius;
        const y = centerY + Math.sin(angle) * finalRadius;

        nodes.push({
          id: `ring-${ringIndex}-${i}`,
          position: { x, y },
          colorSet: COLORS.nodes[i % COLORS.nodes.length],
          size: ring.size,
          delay: 0.2 + (ringIndex * 0.2) + (i * 0.1)
        });
      }
    });

    return nodes;
  }, [dimensions]);

  const nodes = useMemo(() => generateNodes(), [generateNodes]);


  const edges = useMemo(() => {
    const connections = [];
    const connectNodes = (node1, node2, prob = 1) => {
      if (Math.random() < prob) {
        connections.push({
          start: node1.position,
          end: node2.position,
          colorSet: node1.colorSet,
          delay: Math.max(node1.delay, node2.delay) - 0.1
        });
      }
    };
  
    nodes.forEach((node, i) => {
      if (i === 0) return; 
      
      const centerDist = Math.hypot(
        nodes[0].position.x - node.position.x,
        nodes[0].position.y - node.position.y
      );
      

      let closestNode = nodes[0];
      let minDist = centerDist;
      
      nodes.forEach((otherNode, j) => {
        if (i !== j) {
          const dist = Math.hypot(
            otherNode.position.x - node.position.x,
            otherNode.position.y - node.position.y
          );
          if (dist < minDist) {
            minDist = dist;
            closestNode = otherNode;
          }
        }
      });
      

      connectNodes(nodes[0], node, 0.7);
      if (closestNode !== nodes[0]) {
        connectNodes(closestNode, node, 1);
      }
    });
  
  
    nodes.forEach((node1, i) => {
      nodes.slice(i + 1).forEach(node2 => {
        const dist = Math.hypot(
          node1.position.x - node2.position.x,
          node1.position.y - node2.position.y
        );
        if (dist < 30) {
          connectNodes(node1, node2, 0.4);
        }
      });
    });
  
    return connections;
  }, [nodes]);


  


  useEffect(() => {
    const sequence = async () => {
      await new Promise(r => setTimeout(r, 1000));
      
      setPhase('network'); // Show first item
      
      await new Promise(r => setTimeout(r, 2500));
      setPhase('bloom1'); // Show second item
      
      await new Promise(r => setTimeout(r, 2500));
      setPhase('bloom2'); // Show third item
      
      await new Promise(r => setTimeout(r, 2500));
      setPhase('bloom3'); // Show fourth item
      
      await new Promise(r => setTimeout(r, 2000));
      onComplete();
    };
  
    sequence();
  }, [onComplete]);


  useEffect(() => {
    if (phase === 'network' || phase.startsWith('bloom')) {
      const interval = setInterval(() => {
        const randomNode = nodes[Math.floor(Math.random() * nodes.length)];
        setActiveNodes(prev => {
          const next = new Set(prev);
          next.add(randomNode.id);
          setTimeout(() => {
            setActiveNodes(current => {
              const updated = new Set(current);
              updated.delete(randomNode.id);
              return updated;
            });
          }, 2000);
          return next;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [phase, nodes]);

  return ReactDOM.createPortal(
    <>
      <div className={`intro-overlay phase-${phase}`} />
      <div className={`intro-fullscreen phase-${phase}`}>
        <div className="network-container">
          {edges.map((edge, i) => (
            <NetworkEdge
              key={`edge-${i}`}
              {...edge}
              visible={phase !== 'initial'}
            />
          ))}
          {nodes.map((node) => (
            <NetworkNode
              key={node.id}
              {...node}
              visible={phase !== 'initial'}
              isActive={activeNodes.has(node.id)}
            />
          ))}
        </div>
        <div className="intro-text">
          <div className="feature-list">
            <span className="feature">💃 Match with Your Perfect Venues</span>
            <span className="feature">🧐 Find your next audience</span>
            <span className="feature">🚀 See key artists profiles</span>
            <span className="feature final-feature">✨ All on one app</span>
          </div>
        </div>
      </div>
    </>,
    document.body
  );
};

export default IntroAnimation;