import React, { useEffect, useState, useRef, useCallback } from 'react';
import { ForceGraph2D } from 'react-force-graph';
import * as d3 from 'd3';
import './KnowledgeGraphBackground.module.css';

function KnowledgeGraphBackground() {
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [zoomLevel] = useState(3);
  const graphRef = useRef();
  const audioContext = useRef(null);
  const lastPlayTime = useRef(0);
  const lastNodePosition = useRef({ x: 0, y: 0 });
  const lastPosition = useRef({ x: 0, y: 0 });
  
  useEffect(() => {
    audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
    return () => {
      if (audioContext.current) {
        audioContext.current.close();
      }
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function customCenterForce(x, y, strength) {
    let nodes;
  
    function force(alpha) {
      const centerX = x - (window.innerWidth / 2);
      const centerY = y - (window.innerHeight / 2);
  
      nodes.forEach(node => {
        node.x += (centerX - node.x) * strength * alpha;
        node.y += (centerY - node.y) * strength * alpha;
      });
    }
  
    force.initialize = function(_) {
      nodes = _;
    };
  
    return force;
  }

  useEffect(() => {
    if (graphRef.current) {
      graphRef.current.zoom(zoomLevel, 1000);
    }
  }, [zoomLevel]);
  

  useEffect(() => {
    const updateGraphForces = () => {
      if (graphRef.current) {
        const width = window.innerWidth;
        
        // Adjust link distance based on screen size
        let linkDistance;
        if (width < 768) {
          linkDistance = 100;
        } else if (width < 1024) {
          linkDistance = 150;
        } else if (width < 1440) {
          linkDistance = 175;
        } else {
          linkDistance = 200;
        }
        
        // Adjust charge force based on screen size
        let chargeStrength;
        if (width < 768) {
          chargeStrength = -200;
        } else if (width < 1024) {
          chargeStrength = -300;
        } else if (width < 1440) {
          chargeStrength = -350;
        } else {
          chargeStrength = -400;
        }

        const centerForce = customCenterForce(window.innerWidth / 2, window.innerHeight / 2, 0.2);
        graphRef.current.d3Force('center', centerForce);
        const chargeForce = d3.forceManyBody().strength(chargeStrength);
        graphRef.current.d3Force('charge', chargeForce);
        graphRef.current.d3ReheatSimulation();
        graphRef.current.d3Force('link').distance(linkDistance);
      }
    };
  
    updateGraphForces();
    window.addEventListener('resize', updateGraphForces);
    return () => {
      window.removeEventListener('resize', updateGraphForces);
    };
  }, []);
  
  const generateGraphData = () => {
    const nodes = [];
    const links = new Set();
    
    let numberOfNodes;
    const width = window.innerWidth;
    
    if (width < 768) {
      numberOfNodes = 15;
    } else if (width < 1024) {
      numberOfNodes = 25;
    } else if (width < 1440) {
      numberOfNodes = 35;
    } else { 
      numberOfNodes = 40;
    }
    const desiredLinksPerNode = width < 768 ? 1 : width < 1024 ? 1.5 : 2;
  
    const nodeColors = [
      'rgba(255,99,71,1)',    // Tomato
      'rgba(46,204,113,1)',   // Emerald
      'rgba(155,89,182,1)',   // Amethyst
      'rgba(52,152,219,1)',   // Peter River
      'rgba(241,196,15,1)',   // Sun Flower
      'rgba(230,126,34,1)',   // Carrot
      'rgba(231,76,60,1)',    // Alizarin
      'rgba(192,57,43,1)',    // Pomegranate
      'rgba(22,160,133,1)',   // Green Sea
  ];

  // Using A minor pentatonic scale across multiple octaves (A, C, D, E, G)
  const notes = {
      'rgba(255,99,71,1)': 220.00,    // A3 - Low base note
      'rgba(46,204,113,1)': 261.63,   // C4 - Rising tone
      'rgba(155,89,182,1)': 293.66,   // D4 - Gentle middle
      'rgba(52,152,219,1)': 329.63,   // E4 - Sweet spot
      'rgba(241,196,15,1)': 392.00,   // G4 - Bright lift
      'rgba(230,126,34,1)': 440.00,   // A4 - Classic middle
      'rgba(231,76,60,1)': 523.25,    // C5 - Higher sparkle
      'rgba(192,57,43,1)': 587.33,    // D5 - Upper shine
      'rgba(22,160,133,1)': 659.25,    // E5 - Top brightness
  };


  
    for (let i = 1; i <= numberOfNodes; i++) {
      const color = nodeColors[i % nodeColors.length];
      nodes.push({
        id: `Node ${i}`,
        color: color,
        note: notes[color]
      });
    }


    for (let i = 0; i < numberOfNodes; i++) {
      let attempts = 0;
      let linkCount = 0;
      while (linkCount < desiredLinksPerNode && attempts < 1000) {
        const source = nodes[i].id;
        const targetIndex = Math.floor(Math.random() * numberOfNodes);
        const target = nodes[targetIndex].id;
  
        if (source !== target && !links.has(`${source}-${target}`) && !links.has(`${target}-${source}`)) {
          links.add(`${source}-${target}`);
          linkCount++; 
        }
        attempts++;
      }
    }
  
    const linksArray = Array.from(links).map(link => {
      const [source, target] = link.split('-');
      return { source, target };
    });
  
    return { nodes, links: linksArray };
  };

  const createEchoEffect = useCallback((audioContext, inputNode) => {
    const delay1 = audioContext.createDelay(1.0); // Allow up to 1 second delay
    const delay2 = audioContext.createDelay(1.0);
    const feedback1 = audioContext.createGain();
    const feedback2 = audioContext.createGain();
    const echoFilter = audioContext.createBiquadFilter();
    const finalEchoGain = audioContext.createGain();

    delay1.delayTime.value = 0.25;
    feedback1.gain.value = 0.3;
    
    delay2.delayTime.value = 0.4;
    feedback2.gain.value = 0.2;
    
    echoFilter.type = 'lowpass';
    echoFilter.frequency.value = 1500;
    echoFilter.Q.value = 0.5;

    finalEchoGain.gain.value = 0.4;

    inputNode.connect(delay1);
    delay1.connect(feedback1);
    feedback1.connect(delay1);
    delay1.connect(delay2);
    delay2.connect(feedback2);
    feedback2.connect(delay2);
    delay1.connect(echoFilter);
    delay2.connect(echoFilter);
    echoFilter.connect(finalEchoGain);
    finalEchoGain.connect(audioContext.destination);

    return { delay1, delay2, feedback1, feedback2, finalEchoGain };
  }, []);

  const playNote = useCallback((frequency, velocity = 0) => {
    if (!audioContext.current) {
      audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
    }

    const now = audioContext.current.currentTime;
    const duration = 1.2 + (velocity * 0.8); // Longer duration for higher velocity

    const mainGain = audioContext.current.createGain();
    const oscillators = [
      { type: 'sine', ratio: 1, gain: 0.12 }, 
      { type: 'triangle', ratio: 0.5, gain: 0.04 * (1 + velocity) }, // More harmonics with velocity
      { type: 'sine', ratio: 1.01, gain: 0.01 * (1 + velocity) }    // More detuning with velocity
    ].map(({ type, ratio, gain }) => {
      const osc = audioContext.current.createOscillator();
      const oscGain = audioContext.current.createGain();
      
      osc.type = type;
      osc.frequency.value = frequency * ratio;
      
      // More dramatic attack for higher velocities
      oscGain.gain.setValueAtTime(0, now);
      oscGain.gain.linearRampToValueAtTime(gain * (1 + velocity * 0.5), now + 0.1 * (1 - velocity * 0.5));
      oscGain.gain.exponentialRampToValueAtTime(gain * 0.7, now + 0.3);
      oscGain.gain.exponentialRampToValueAtTime(0.001, now + duration);
      
      osc.connect(oscGain);
      oscGain.connect(mainGain);
      
      return osc;
    });

    const filter = audioContext.current.createBiquadFilter();
    filter.type = 'lowpass';
    // Higher velocity opens the filter more
    filter.frequency.value = 2000 + (velocity * 2000);
    filter.Q.value = 0.5 + (velocity * 2);

    mainGain.connect(filter);
    filter.connect(audioContext.current.destination);

    // Enhanced echo effect based on velocity
    if (velocity > 0) {
      const echoEffects = createEchoEffect(audioContext.current, filter);
      
      // More echo for faster movements
      const echoAmount = Math.min(0.6, velocity * 0.3);
      echoEffects.finalEchoGain.gain.value = echoAmount;
      
      // Longer echo tail for faster movements
      echoEffects.feedback1.gain.value = Math.min(0.5, 0.2 + velocity * 0.2);
      echoEffects.delay1.delayTime.value = 0.25 + (velocity * 0.1);
      echoEffects.delay2.delayTime.value = 0.4 + (velocity * 0.15);
    }

    oscillators.forEach(osc => {
      osc.start(now);
      osc.stop(now + duration);
    });
  }, [createEchoEffect]);

  const handleNodeDrag = useCallback((node) => {
    if (!node?.note) return;

    const now = Date.now();
    const timeDiff = now - lastPlayTime.current;
    
    const currentX = node.x ?? 0;
    const currentY = node.y ?? 0;
    
    const dx = currentX - lastPosition.current.x;
    const dy = currentY - lastPosition.current.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    
    
    if (distance > 2 && timeDiff > 50) {
      
      const velocity = Math.min(distance / 20, 1);
      
      
      const pitchBend = 1 + (dy / window.innerHeight) * 0.1; 
      const adjustedFrequency = node.note * pitchBend;
      

      playNote(adjustedFrequency, velocity);
      
      lastPosition.current = { x: currentX, y: currentY };
      lastPlayTime.current = now;
    }
  }, [playNote]);


  const handleNodeClick = useCallback((node) => {
    if (node && node.note) {
      playNote(node.note, 0);
    }
  }, [playNote]);

  const graphData = generateGraphData();

  return (
      <ForceGraph2D
        ref={graphRef}
        graphData={graphData}
        width={dimensions.width}
        height={dimensions.height}
        enableZoomInteraction={false}
        enablePanInteraction={false}
        backgroundColor="rgba(0,0,0,0)"
        nodeColor={node => node.color}
        linkColor={() => 'rgba(128,128,128,0.5)'}
        linkDirectionalParticles={2}
        linkDirectionalParticleWidth={2}
        linkDirectionalParticleSpeed={0.0005}
        onNodeDrag={handleNodeDrag} 
        onNodeDragEnd={handleNodeDrag}
        nodeVal={1}
        onNodeClick={handleNodeClick}
        nodeCanvasObject={(node, ctx, globalScale) => {
          if (isFinite(node.x) && isFinite(node.y)) {
            const hazeSize = isMobile ? 15 : 20;
            const nodeSize = isMobile ? 9 : 12;
            const gradient = ctx.createRadialGradient(node.x, node.y, 0, node.x, node.y, hazeSize);
            gradient.addColorStop(0, 'rgba(255, 255, 255, 0.2)');
            gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');
            ctx.fillStyle = gradient;
            ctx.beginPath();
            ctx.arc(node.x, node.y, hazeSize, 0, 2 * Math.PI, false);
            ctx.fill();
            ctx.beginPath();
            ctx.arc(node.x, node.y, nodeSize, 0, 2 * Math.PI, false);
            ctx.fillStyle = node.color;
            ctx.fill();
          }
        }}
        d3AlphaMin={0}
        d3AlphaTarget={0.001}
      />
  );
}

export default KnowledgeGraphBackground;