import React from 'react';


const AddArtist = ({ onBackToHome }) => {
  return (
    <div className={"content-class"}>
      <div className="add-artist-content">
        <h2>Add Artist Feature 🎉</h2>
        <p>Hey there, Synspire User! 🎧</p>
        <p>
          We're excited to announce that the "Add Artist" feature is coming your way very soon! 
          🚀 Our team is working hard behind the scenes to make it super awesome for you. 💪
        </p>
        <p>
          In the meantime, we recommend using our search functionality to find your artists. 
          Trust us, we've got a ton of them already! 😉
        </p>
        <p>
          Stay tuned for the official launch of "Add Artist" in the next month. 
          We promise it'll be worth the wait! 🎉
        </p>
        <button 
          className="inspire-button" 
          onClick={() => onBackToHome()}
        >
          Back to Search 🧐
        </button>
      </div>
    </div>
  );
};


export default AddArtist;