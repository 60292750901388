

const apiKey = process.env.REACT_APP_API_KEY;

const generateTimestamp = () => {
  return Date.now().toString();
};

const reobfuscateApiKey = (timestamp) => {
  var high = timestamp.substring(timestamp.length - 6);
  var low = (parseInt(high) >> 1).toString();
  var obfuscatedApiKey = '';

    while (low.length < 6) {
      low = '0' + low;
  }

  for (var i = 0; i < high.length; i++) {
      obfuscatedApiKey += apiKey.charAt(parseInt(high.charAt(i)));
  }

  for (var j = 0; j < low.length; j++) {
      obfuscatedApiKey += apiKey.charAt(parseInt(low.charAt(j)) + 2);
  }

  return obfuscatedApiKey;
};

const getObfuscatedKeyWithTimestamp = () => {
  const timestamp = generateTimestamp();
  const obfuscatedKey = reobfuscateApiKey(timestamp);
  return { obfuscatedKey, timestamp };
};


export { getObfuscatedKeyWithTimestamp };