import React, { useState, useEffect, useRef } from 'react';
import { Users, Zap, Star, MapPin, Music,Rocket, ArrowRight, Heart} from 'lucide-react';
import './css/about.css';
import Synspire_logo from './images/Synspire_logo.png';

const AboutPage = () => {
  const [visibleSections, setVisibleSections] = useState([]);
  const sectionRefs = useRef([]);
  const [countedMetrics, setCountedMetrics] = useState({});

  useEffect(() => {
    const sectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisibleSections((prev) => [...new Set([...prev, entry.target.id])]);
          }
          document.querySelectorAll('.metric').forEach(metric => {
          });
        });
      },
      { threshold: 0.1 }
    );

    const metricObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            animateCount(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    sectionRefs.current.forEach((ref) => {
      if (ref) sectionObserver.observe(ref);
    });

    document.querySelectorAll('.metric').forEach((metric) => {
      metricObserver.observe(metric);
    });

    return () => {
      sectionRefs.current.forEach((ref) => {
        if (ref) sectionObserver.unobserve(ref);
      });
      document.querySelectorAll('.metric').forEach((metric) => {
        metricObserver.unobserve(metric);
      });
    };
  }, []);



  const animateCount = (target) => {
    const value = parseFloat(target.dataset.value);
    const suffix = target.dataset.suffix || '';
    const id = target.id;
    if (countedMetrics[id]) return;

    let startValue = 0;
    const duration = 2000;
    const startTime = Date.now();

    const updateCount = () => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime;
      if (elapsedTime < duration) {
        const progress = elapsedTime / duration;
        startValue = value * progress;
        const displayValue = Number.isInteger(value) ? 
          Math.floor(startValue) : 
          startValue.toFixed(1);
        
        const valueElement = target.querySelector('.metric-value');
        if (valueElement) {
          valueElement.textContent = displayValue + suffix;
        } else {
          console.error('Metric value element not found for', target.id);
        }
        
        requestAnimationFrame(updateCount);
      } else {
        const valueElement = target.querySelector('.metric-value');
        if (valueElement) {
          valueElement.textContent = value + suffix;
        } else {
          console.error('Metric value element not found for', target.id);
        }
        setCountedMetrics((prev) => ({ ...prev, [id]: true }));
      }
    };

    requestAnimationFrame(updateCount);
  };



  
  const handleContactUs = () => {
    const email = 'contact@synspireforartists.com';
    const subject = 'Inquiry about Synspire';
    const body = 'Hello,\n\nI would like to know more about Synspire and how it can help my music career';
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  const sections = [
   {
    id: "vision",
    title: "",
    icon:<></>,
    content: (
      <div className="vision-content">
        <div className="vision-description">
          <p>Behind every track, there's an artist with dreams, talent, and determination. In today's digital age, over 60,000 tracks are released daily - each one a story waiting to be heard. We believe every artist deserves the chance to find their audience.</p>
        </div>

        <div className="overview-grid">
          <div className="overview-feature">
            <div className="overview-feature-icon">
              <Heart className="text-red-500" size={32} />
            </div>
            <div className="overview-feature-content">
              <h3 className="overview-feature-title">Our Promise</h3>
              <p className="overview-feature-description">Transform how artists build careers by making professional analytics accessible to all</p>
            </div>
          </div>
          
          <div className="overview-feature">
            <div className="overview-feature-icon">
              <Star className="text-yellow-500" size={32} />
            </div>
            <div className="overview-feature-content">
              <h3 className="overview-feature-title">Our Mission</h3>
              <p className="overview-feature-description">Democratize success in the creative industries through data-driven insights</p>
            </div>
          </div>

          <div className="overview-feature">
            <div className="overview-feature-icon">
              <Zap className="text-blue-500" size={32} />
            </div>
            <div className="overview-feature-content">
              <h3 className="overview-feature-title">Our Impact</h3>
              <p className="overview-feature-description">Build a community where every artist has the tools to thrive</p>
            </div>
          </div>
        </div>
      </div>
    )
  },
  {
    id: "features",
    title: "Your Journey With Synspire",
    icon: <Rocket className="section-icon" size={40} />,
    content: (
      <div className="features-content">
        <div className="journey-flow">
          {[
            {
              stage: "STAGE 1",
              title: "Discover Your unique DNA",
              icon: "🎨",
              description: "It starts with understanding what makes you unique, we use technology to discover your online persona.",
              mainFeature: {
                title: "Artist DNA Profile",
                description: "Our AI learns your musical fingerprint - from your how you present your story online",
                details: ["Genre Fusion Analysis", "Style Recognition", "Audience Persona"]
              },
              visualElement: <Music className="journey-visual" size={40} />,
              insight: "Just like your music, your path to success should be unique to you."
            },
            {
              stage: "STAGE 2",
              title: "Connect with your Tribe",
              icon: "💫",
              description: "Find future audiences that naturally vibe with your sound",
              mainFeature: {
                title: "Smart Audience Finder",
                description: "Use this tool, to brainstorm and find your perfect next audience",
                details: ["Community Hotspots", "Fan DNA Matching", "Content Suggestions","Brand Alignment"]
              },
              visualElement: <Users className="journey-visual" size={40} />,
              insight: "Find the people who will love your music - before they even hear it."
            },
            {
              stage: "STAGE 3",
              title: "Find your Stage",
              icon: "🎯",
              description: "Use the Venue Match tool to find the perfect stage for your next performance and create a tour that resonates with your audience.",
              mainFeature: {
                title: "Venue Match",
                description: "Match your vibe with the perfect venue for your next performance by using our own online location scouting tool",
                details: ["Venue Vibe Analysis", "Performance Fit Score", "Success Prediction"]
              },
              visualElement: <MapPin className="journey-visual" size={40} />,
              insight: "Every venue has a soul - we'll help you find the ones that match yours."
            },
          ].map((stage, index) => (
            <div key={index} className={`journey-stage ${index % 2 === 0 ? 'stage-left' : 'stage-right'}`}>
              <div className="stage-content">
                <div className="stage-header">
                  <div className="stage-icon">{stage.icon}</div>
                  <h3 className="stage-title">{stage.title}</h3>
                </div>
                
                <p className="stage-description">{stage.description}</p>
  
                <div className="stage-feature">
                  <h4 className="feature-title">{stage.mainFeature.title}</h4>
                  <p className="feature-description">{stage.mainFeature.description}</p>
                  <div className="feature-details">
                    {stage.mainFeature.details.map((detail, i) => (
                      <span key={i} className="feature-tag">{detail}</span>
                    ))}
                  </div>
                </div>
  
                <div className="stage-insight">
                  <Star className="insight-icon" size={16} />
                  <p>{stage.insight}</p>
                </div>
              </div>
  
              <div className="stage-visual">
                {stage.visualElement}
                <div className="visual-connector"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
];

return (
    <div className="about-page-container">
      <div className="about-page-scroll">
        <div className="about-page">
          <header className="about-header">
            <img src={Synspire_logo} alt="Synspire Logo" className="logo"/>
            <p className="tagline"><i>Actionable Insights For Musicians</i></p>
          </header>
          
          {sections.map((section, index) => (
            <div
              key={section.id}
              id={section.id}
              ref={(el) => (sectionRefs.current[index] = el)}
              className={`section ${visibleSections.includes(section.id) ? 'visible' : ''}`}
            >
              <div className="section-header">
                {section.icon}
                <h2 className="section-title">{section.title}</h2>
              </div>
              <div className="section-content">
                {section.content}
              </div>
            </div>
          ))}
          <section className="story-moment revolution">
            <div className="content-reveal">
              <h3>Be Part of The Change ✨ </h3>
              <p className="revolution-text">
                Today, we're building a future where every artist 
                has the tools to find their audience and build a sustainable career.
              </p>
              <div className="call-to-action">
                <button className="cta-button primary" onClick={() => handleContactUs()}>
                  Start Your Journey
                  <ArrowRight size={16} />
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;