import React, { useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import * as THREE from 'three';
import L from 'leaflet';

const ThreeJSOverlay = ({ venues, maxCount, onLocationSelect }) => {
  const map = useMap();
  const mountRef = useRef(null);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const rendererRef = useRef(null);
  const pinsRef = useRef([]);
  const animationFrameRef = useRef(null);

  const PIN_HEIGHT = 80;
  const PIN_RADIUS_TOP = 15;
  const PIN_RADIUS_BOTTOM = 2;
  const PIN_SEGMENTS = 6;
  const CLUSTER_RADIUS = 30; 

  const UK_CITIES = [
    { name: "London", lat: 51.5074, lon: -0.1278 },
    { name: "Oxford", lat: 51.7520, lon: -1.2577 },
  ];



  const PIN_COLORS = {
    default: new THREE.Color(0x3498db),  // Peter River Blue
    highlight: new THREE.Color(0xf1c40f), // Sun Flower Yellow
    active: new THREE.Color(0xe74c3c),    // Alizarin Red
    hover: new THREE.Color(0x2ecc71),     // Emerald Green
    emissive: new THREE.Color(0x2c3e50),  // Dark Blue
  };


  const getDistanceInKm = (lat1, lon1, lat2, lon2) => {
    const R = 6371;
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
              Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
              Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    return R * c;
  };


  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Earth's radius in km
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
              Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
              Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    return R * c;
  };
  
  // Function to find nearest city
  const findNearestCity = (lat, lon) => {
    let nearestCity = UK_CITIES[0];
    let shortestDistance = calculateDistance(lat, lon, UK_CITIES[0].lat, UK_CITIES[0].lon);
  
    UK_CITIES.forEach(city => {
      const distance = calculateDistance(lat, lon, city.lat, city.lon);
      if (distance < shortestDistance) {
        shortestDistance = distance;
        nearestCity = city;
      }
    });
  
    return nearestCity.name;
  };


  const createPin = (cluster) => {
    // Create twisted hexagonal geometry
    const geometry = new THREE.CylinderGeometry(
      PIN_RADIUS_TOP,
      PIN_RADIUS_BOTTOM,
      PIN_HEIGHT,
      PIN_SEGMENTS
    );

    // Apply twist effect
    const positions = geometry.attributes.position.array;
    for (let i = 0; i < positions.length; i += 3) {
      const y = positions[i + 1];
      const angle = (y / PIN_HEIGHT) * Math.PI * 0.25;
      const x = positions[i];
      const z = positions[i + 2];
      positions[i] = x * Math.cos(angle) - z * Math.sin(angle);
      positions[i + 2] = x * Math.sin(angle) + z * Math.cos(angle);
    }

    // Enhanced material with modern effects
    const material = new THREE.MeshPhongMaterial({
      color: PIN_COLORS.default,
      emissive: PIN_COLORS.emissive,
      emissiveIntensity: 0.2,
      shininess: 80,
      transparent: true,
      opacity: 0.9,
      specular: new THREE.Color(0x555555),
      flatShading: true,
    });

    const pin = new THREE.Mesh(geometry, material);

    // Add base circle with glow effect
    const baseGeometry = new THREE.CircleGeometry(PIN_RADIUS_TOP * 1.5, PIN_SEGMENTS);
    const baseMaterial = new THREE.MeshBasicMaterial({
      color: PIN_COLORS.default,
      transparent: true,
      opacity: 0.3,
      side: THREE.DoubleSide,
    });
    const base = new THREE.Mesh(baseGeometry, baseMaterial);
    base.rotation.x = -Math.PI / 2;
    base.position.y = 0.1;
    pin.add(base);

    // Add glow effect
    const glowGeometry = geometry.clone();
    const glowMaterial = new THREE.MeshBasicMaterial({
      color: PIN_COLORS.highlight,
      transparent: true,
      opacity: 0.3,
      side: THREE.DoubleSide,
    });
    const glow = new THREE.Mesh(glowGeometry, glowMaterial);
    glow.scale.multiplyScalar(1.1);
    pin.add(glow);

    return pin;
  };

  // Cluster venues
  const clusterVenues = (venues) => {
    const clusters = [];
    const processedVenues = new Set();

    venues.forEach(venue => {
      if (!venue.GPS || !venue.GPS.lat || !venue.GPS.lon || processedVenues.has(venue)) return;

      const cluster = {
        venues: [venue],
        center: { lat: venue.GPS.lat, lon: venue.GPS.lon },
        count: 1
      };
      processedVenues.add(venue);

      venues.forEach(otherVenue => {
        if (otherVenue === venue || !otherVenue.GPS || processedVenues.has(otherVenue)) return;

        const distance = getDistanceInKm(
          venue.GPS.lat, venue.GPS.lon,
          otherVenue.GPS.lat, otherVenue.GPS.lon
        );

        if (distance <= CLUSTER_RADIUS) {
          cluster.venues.push(otherVenue);
          cluster.count++;
          processedVenues.add(otherVenue);

          // Update cluster center
          cluster.center.lat = cluster.venues.reduce((sum, v) => sum + v.GPS.lat, 0) / cluster.venues.length;
          cluster.center.lon = cluster.venues.reduce((sum, v) => sum + v.GPS.lon, 0) / cluster.venues.length;
        }
      });

      clusters.push(cluster);
    });

    return clusters;
  };

  // Convert lat/lng to world coordinates with proper scaling
  const latLngToWorld = (latLng) => {
    const mapBounds = map.getBounds();
    const mapCenter = map.getCenter();
    
    const normalizedX = (latLng.lng - mapBounds.getWest()) / (mapBounds.getEast() - mapBounds.getWest());
    const normalizedY = (latLng.lat - mapBounds.getSouth()) / (mapBounds.getNorth() - mapBounds.getSouth());
    
    const mapSize = map.getSize();
    const pixelX = normalizedX * mapSize.x - mapSize.x / 2;
    const pixelY = (1 - normalizedY) * mapSize.y - mapSize.y / 2;
    
    return { x: pixelX, z: pixelY };
  };

  // Initialize Three.js with enhanced lighting
  useEffect(() => {
    if (!mountRef.current || !map) return;

    const scene = new THREE.Scene();
    sceneRef.current = scene;

    const mapSize = map.getSize();
    const camera = new THREE.OrthographicCamera(
      -mapSize.x / 2,
      mapSize.x / 2,
      mapSize.y / 2,
      -mapSize.y / 2,
      -1000,
      1000
    );
    camera.position.set(0, 100, 0);
    camera.lookAt(0, 0, 0);
    cameraRef.current = camera;

    const renderer = new THREE.WebGLRenderer({
      canvas: mountRef.current,
      antialias: true,
      alpha: true,
    });
    renderer.setSize(mapSize.x, mapSize.y);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setClearColor(0x000000, 0);
    rendererRef.current = renderer;

    // Enhanced lighting setup
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
    scene.add(ambientLight);
    
    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
    directionalLight.position.set(1, 1, 1);
    scene.add(directionalLight);

    // Add point lights for dramatic effect
    const pointLight1 = new THREE.PointLight(0x3498db, 0.5, 1000);
    pointLight1.position.set(200, 200, 200);
    scene.add(pointLight1);

    const pointLight2 = new THREE.PointLight(0x2ecc71, 0.5, 1000);
    pointLight2.position.set(-200, 200, -200);
    scene.add(pointLight2);

    return () => {
      renderer.dispose();
      rendererRef.current = null;
    };
  }, [map]);

  // Handle venues and updates with enhanced animations
  useEffect(() => {
    if (!map || !venues.length || !sceneRef.current || !rendererRef.current || !cameraRef.current) return;

    const scene = sceneRef.current;
    const renderer = rendererRef.current;
    const camera = cameraRef.current;

    // Clear existing pins
    pinsRef.current.forEach(pin => {
      if (pin.geometry) pin.geometry.dispose();
      if (pin.material) pin.material.dispose();
      scene.remove(pin);
    });
    pinsRef.current = [];

    // Create and place enhanced pins
    const clusters = clusterVenues(venues);
    const maxClusterSize = Math.max(...clusters.map(c => c.count));

    clusters.forEach(cluster => {
      const latLng = L.latLng(cluster.center.lat, cluster.center.lon);
      const worldPos = latLngToWorld(latLng);

      const heightScale = cluster.count / maxClusterSize;
      const height = PIN_HEIGHT + (PIN_HEIGHT * heightScale);

      const pin = createPin(cluster);
      pin.position.set(worldPos.x, height / 2, worldPos.z);
      
      pin.userData = {
        cluster,
        latLng,
        count: cluster.count,
      };

      scene.add(pin);
      pinsRef.current.push(pin);
    });

    const updatePositions = () => {
      const mapSize = map.getSize();
      
      camera.left = -mapSize.x / 2;
      camera.right = mapSize.x / 2;
      camera.top = mapSize.y / 2;
      camera.bottom = -mapSize.y / 2;
      camera.updateProjectionMatrix();

      pinsRef.current.forEach(pin => {
        if (pin?.userData?.latLng) {
          const worldPos = latLngToWorld(pin.userData.latLng);
          pin.position.x = worldPos.x;
          pin.position.z = worldPos.z;

          // Add subtle floating animation
          pin.position.y += Math.sin(Date.now() * 0.002) * 0.1;
        }
      });

      renderer.render(scene, camera);
    };

    const animate = () => {
      animationFrameRef.current = requestAnimationFrame(animate);
      updatePositions();
    };

    animate();

    map.on('move zoom viewreset', updatePositions);
    mountRef.current?.addEventListener('click', handleClick);

    const handleResize = () => {
      const size = map.getSize();
      renderer.setSize(size.x, size.y);
      updatePositions();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      map.off('move zoom viewreset', updatePositions);
      mountRef.current?.removeEventListener('click', handleClick);
      window.removeEventListener('resize', handleResize);

      pinsRef.current.forEach(pin => {
        if (pin.geometry) pin.geometry.dispose();
        if (pin.material) pin.material.dispose();
        scene.remove(pin);
      });
      pinsRef.current = [];
    };
  }, [map, venues, maxCount]);

  const handleClick = (event) => {
    if (!mountRef.current || !cameraRef.current) return;
  
    event.stopPropagation();
    
    const rect = mountRef.current.getBoundingClientRect();
    const mouse = new THREE.Vector2(
      ((event.clientX - rect.left) / rect.width) * 2 - 1,
      -((event.clientY - rect.top) / rect.height) * 2 + 1
    );
  
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, cameraRef.current);
  
    const intersects = raycaster.intersectObjects(pinsRef.current);
    if (intersects.length > 0) {
      const selectedObject = intersects[0].object;
      const userData = selectedObject.userData;
      
      if (userData && userData.cluster && userData.cluster.venues && userData.cluster.venues.length > 0) {
        const venue = userData.cluster.venues[0];
        
        const cityName = venue.GPS ? findNearestCity(venue.GPS.lat, venue.GPS.lon) : null;
  
        selectedObject.material.color.set(PIN_COLORS.active);
        selectedObject.scale.set(1.2, 1.2, 1.2);
        
        setTimeout(() => {
          selectedObject.material.color.set(PIN_COLORS.default);
          selectedObject.scale.set(1, 1, 1);
        }, 1000);
        console.log('Selected venue:', venue, 'City:', cityName);

        if (cityName) {
          onLocationSelect(cityName);
        }

      }
    }
  };

  return (
    <canvas
      ref={mountRef}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'auto',
        zIndex: 1000,
      }}
    />
  );
};

export default ThreeJSOverlay;