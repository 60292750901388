import React, { useEffect, useState, useRef, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ForceGraph2D } from 'react-force-graph';
import {LayoutDashboard, Info, Undo2, Download,House } from 'lucide-react';
import { getObfuscatedKeyWithTimestamp } from '../../services/policy_delta';
import * as d3 from 'd3';
import styles from './AudienceFinder.module.css';

const isMobile = window.innerWidth <= 768;

const NODE_COLORS = {
  artist: 'rgba(255, 215, 0, 1)',  // Gold for artist
  current_audience: 'rgba(155, 89, 182, 1)',  // Amethyst
  potential_audience: 'rgba(46, 204, 113, 1)', // Emerald
  content_action: 'rgba(52, 152, 219, 1)',    // Peter River
  brand_perception: 'rgba(241, 196, 15, 1)'   // Sun Flower
};


const DEFAULT_LINK_DISTANCE = isMobile ? 1000 : 6000;

const NODE_SIZES = {
  artist: isMobile ? 40 : 80,
  current_audience: isMobile ? 30 : 60,
  potential_audience: isMobile ? 20 : 40,
  content_action: isMobile ? 15 : 30,
  brand_perception: isMobile ? 10 : 20,
};


const NAVIGATION_LEVELS = {
  ARTIST: 'artist',
  CURRENT_AUDIENCE: 'current_audience',
  POTENTIAL_AUDIENCE: 'potential_audience',
  CONTENT_ACTION: 'content_action',
  BRAND_PERCEPTION: 'brand_perception'
};

const AudienceFinder = ({ artistName, onBackToDashboard, onBack }) => {
  const [isMobileNodeInfoOpen, setIsMobileNodeInfoOpen] = useState(false);
  const [fullGraphData, setFullGraphData] = useState({ nodes: [], links: [] });
  const [visibleGraphData, setVisibleGraphData] = useState({ nodes: [], links: [] });
  const [hoverNode, setHoverNode] = useState(null);
  const [displayedNode, setDisplayedNode] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [navigationHistory, setnavigationHistory] = useState([]);
  const navigationHistoryRef = useRef([]);
  const [touchTimeout, setTouchTimeout] = useState(null);
  const [touchStartTime, setTouchStartTime] = useState(0);
  const [isTouching, setIsTouching] = useState(false);
  const graphInitializedRef = useRef(false);
  const [error, setError] = useState(null);
  const [shouldFocus, setShouldFocus] = useState(true);
  const infoBoxRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [showHelp, setShowHelp] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [currentView, setCurrentView] = useState({
    level: NAVIGATION_LEVELS.ARTIST,
    title: ` ${artistName} Audience Overview`
  });
  const graphRef = useRef();


  const getViewInfo = useCallback((nodeType, nodeName) => {
    const getNodeName = nodeName.replace(/_/g, ' ').toLowerCase().split(' ').map((word, index) => index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word).join(' ');
    switch (nodeType) {
      case NAVIGATION_LEVELS.ARTIST:
        return {
          title: `'${artistName.replace(/\b\w/g, char => char.toUpperCase())}' Current  Audience`,
          description: 'Explore the different possible audience segments for this artist'
        };
      case NAVIGATION_LEVELS.CURRENT_AUDIENCE:
        return {
          title: `🪩 Potential Audiences from '${getNodeName}'`,
          description: 'You ever wondering who else might like this artist? 🧐 Find out here!'
        };
      case NAVIGATION_LEVELS.POTENTIAL_AUDIENCE:
        return {
          title: `📝 Content ideas to reach '${getNodeName}'`,
          description: 'Now that you know who they are, what will resonate with them?'
        };
      case NAVIGATION_LEVELS.CONTENT_ACTION:
        return {
          title: `The impacts for '${getNodeName}'`,
          description: 'What will be the impact of this strategy on your brand perception?'
        };
      case NAVIGATION_LEVELS.BRAND_PERCEPTION:
        return {
          title: 'Brand Impact Analysis',
          description: 'Understand how this strategy affects your brand perception'
        };
      default:
        return {
          title: 'Audience Explorer',
          description: 'Navigate your audience growth journey'
        };
    }
  }, []);

  const graphOpsRef = useRef({
    centerNode: (node) => {
      if (graphRef.current && node) {
        const graph = graphRef.current;
        
        graph.centerAt(node.x, node.y, 1000);

        graph.zoomToFit(1000, 400, node => {
            return visibleGraphData.nodes.some(visibleNode => visibleNode.id === node.id);
        });
      }
    },
    updateLayout: () => {
      if (graphRef.current) {
        const graph = graphRef.current;
        const centerX = window.innerWidth / 2;
        const centerY = window.innerHeight / 2;

        graph.centerAt(centerX, centerY, 1000);

        setTimeout(() => {
          graph.zoomToFit(1000, 400, node => {
            return visibleGraphData.nodes.some(visibleNode => visibleNode.id === node.id);
          });
        }, 100);
      }
    }
  });
  
  const focusNode = useCallback((node) => {
    if (!graphRef.current || !node) return;
    
    const graph = graphRef.current;
    const centerX = window.innerWidth / 2;
  
    if (isMobile) {
      const centerY = window.innerHeight / 3;
      graph.centerAt(centerX, centerY, 1000);
      
      setTimeout(() => {
        graph.zoom(12, 0);
        
        setTimeout(() => {
          graph.zoomToFit(1000, 50, n => {
            return visibleGraphData.nodes.some(visibleNode => visibleNode.id === n.id);
          });
        }, 100);
      }, 50);
    } else {
      graph.centerAt(node.x, node.y, 1000);
      graph.zoomToFit(1000, 200, n => {
        return visibleGraphData.nodes.some(visibleNode => visibleNode.id === n.id);
      });
    }
  }, [visibleGraphData]);
  
  
  
  const findConnectedNodes = useCallback((sourceNode, nodeType, fullData) => {
    const connectedLinks = fullData.links.filter(link => 
      (link.source === sourceNode.id || (link.source.id === sourceNode.id)) &&
      fullData.nodes.find(n => 
        (n.id === link.target || n.id === link.target.id) && 
        n.type === nodeType
      )
    );

    const connectedNodes = connectedLinks.map(link => 
      fullData.nodes.find(n => 
        n.id === (typeof link.target === 'object' ? link.target.id : link.target)
      )
    ).filter(Boolean);

    return { nodes: connectedNodes, links: connectedLinks };
  }, []);



  const updateVisibleGraph = useCallback((node, fullData, isBackNavigation = false) => {
    if (!node || !fullData) return;

    let visibleNodes = [];
    let visibleLinks = [];
    
    const previousCentralNode = navigationHistoryRef.current.length > 1 ? 
      navigationHistoryRef.current[navigationHistoryRef.current.length - 2] : null;


    switch (node.type) {
      case NAVIGATION_LEVELS.ARTIST:
        visibleNodes = [
          node,
          ...fullData.nodes.filter(n => n.type === NAVIGATION_LEVELS.CURRENT_AUDIENCE)
        ];
        visibleLinks = fullData.links.filter(link => 
          link.source === node.id || 
          link.target === node.id
        );
        break;

      case NAVIGATION_LEVELS.CURRENT_AUDIENCE:
        const { nodes: potentialNodes, links: potentialLinks } = 
          findConnectedNodes(node, NAVIGATION_LEVELS.POTENTIAL_AUDIENCE, fullData);
        visibleNodes = [node, ...potentialNodes];
        visibleLinks = potentialLinks;
        break;

      case NAVIGATION_LEVELS.POTENTIAL_AUDIENCE:
        const { nodes: contentNodes, links: contentLinks } = 
          findConnectedNodes(node, NAVIGATION_LEVELS.CONTENT_ACTION, fullData);
        visibleNodes = [node, ...contentNodes];
        visibleLinks = contentLinks;
        break;

      case NAVIGATION_LEVELS.CONTENT_ACTION:
        const { nodes: perceptionNodes, links: perceptionLinks } = 
          findConnectedNodes(node, NAVIGATION_LEVELS.BRAND_PERCEPTION, fullData);
        visibleNodes = [node, ...perceptionNodes];
        visibleLinks = perceptionLinks;
        break;

      case NAVIGATION_LEVELS.BRAND_PERCEPTION:
        visibleNodes = [node];
        visibleLinks = [];
        break;

      default:
        visibleNodes = [node];
        visibleLinks = [];
    }
    const centerX = window.innerWidth / 2;
    const previousNodeY = window.innerHeight - 150; 
    const verticalSpacing = 550;
    const centerY = previousNodeY - verticalSpacing;
    const regularNodesY = centerY - verticalSpacing; 

visibleNodes = visibleNodes.map((n, index) => {
  if (n.id === node.id) {
    return {
      ...n,
      x: centerX,
      y: centerY,
      fx: centerX,
      fy: centerY,
      opacity: 1
    };
  }
      

      const totalNodes = visibleNodes.filter(vn => vn.id !== node.id).length;
      const arcWidth = Math.min(window.innerWidth * 0.8, 1200); 
      const spacing = arcWidth / Math.max(totalNodes, 1);
 
      const nodeIndex = visibleNodes.indexOf(n) >= visibleNodes.indexOf(node) ? 
        visibleNodes.indexOf(n) - 1 : visibleNodes.indexOf(n);
      
      const xOffset = (nodeIndex - (totalNodes - 1) / 2) * spacing;
      
      return {
        ...n,
        x: centerX + xOffset,
        y: regularNodesY,
        fx: centerX + xOffset,
        fy: regularNodesY,
        opacity: 1
      };
    });

    if (previousCentralNode && previousCentralNode.id !== node.id) {
      const previousNodeCopy = {
        ...previousCentralNode,
        x: centerX,
        y: previousNodeY,
        fx: centerX,
        fy: previousNodeY,
        opacity: 0.5
      };
      
      visibleNodes.push(previousNodeCopy);
      
      visibleLinks.push({
        source: previousNodeCopy.id,
        target: node.id,
        value: 1,
        opacity: 0.5
      });
    }
    setVisibleGraphData({
      nodes: visibleNodes,
      links: visibleLinks.map(link => ({
        ...link,
        value: link.value || 1
      }))
    });
  
    if (!isBackNavigation && shouldFocus && graphInitializedRef.current) {
      requestAnimationFrame(() => {
        if (graphRef.current) {
          const graph = graphRef.current;
          
          graph.centerAt(
            node.x,
            node.y + (window.innerHeight * 0.2),
            1000
          );
          
          setTimeout(() => {
            graph.zoomToFit(1000, 200, n => {
              const isVisible = visibleNodes.some(vn => vn.id === n.id);
              const isPreviousNode = previousCentralNode && n.id === previousCentralNode.id;
              return isVisible || isPreviousNode;
            });
          }, 100);
        }
      });
    }
  }, [findConnectedNodes, getViewInfo, shouldFocus]);


  const handleNodeClick = useCallback((node) => {
    if (!node) return;
    
    if (window.innerWidth <= 768) {
      setIsMobileNodeInfoOpen(true);
    }
    
    let newHistory;
    
    const existingLevelIndex = navigationHistory.findIndex(n => n.type === node.type);
    
    if (existingLevelIndex !== -1) {
      newHistory = [...navigationHistory.slice(0, existingLevelIndex)];
    } 
    else {
      newHistory = [...navigationHistory];
    }
  
    newHistory.push(node);
    
    navigationHistoryRef.current = newHistory;
    setnavigationHistory(newHistory);
    setSelectedNode(node);
    updateVisibleGraph(node, fullGraphData);
    setCurrentView(getViewInfo(node.type, node.name));
    setDisplayedNode(node);
    focusNode(node);
  }, [fullGraphData, updateVisibleGraph, navigationHistory]);
      

  const handleNavigation = useCallback((direction) => {
    if (direction === 'back' && navigationHistory.length > 1) {

      const newHistory = navigationHistory.slice(0, -1);
      const previousNode = newHistory[newHistory.length - 1];
      
      navigationHistoryRef.current = newHistory;
      setnavigationHistory(newHistory);
      setSelectedNode(previousNode);
      setCurrentView(getViewInfo(previousNode.type, previousNode.name));
      updateVisibleGraph(previousNode, fullGraphData, true);
    }
  }, [navigationHistory, fullGraphData, updateVisibleGraph]);

  const handleNodeHover = useCallback((node) => {
    if (node) {
      setDisplayedNode(node);
    }
  }, []);




  const handleMobileNodeInfoClose = useCallback(() => {
    setIsMobileNodeInfoOpen(false);
  }, []);

  const renderNodeInfo = useCallback((node) => {
    if (!node) return null;
  
    const nodeTitle = node.name.replace(/_/g, ' ').toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  
    return (
      <div className={`${styles.nodeInfoBox}`}>
      <div className={styles.nodeInfoHeader}>
        <h2>{nodeTitle}</h2>
      </div>
      <div className={styles.nodeInfoContent}>
        {node.type === 'artist' && (
        <div className={styles.artistInfo}>
          <div className={styles.infoItem}>
          <span className={styles.infoValue}>{node.artistData.bio}</span>
          </div>
          <div className={styles.infoItem}>
            <span className={styles.infoLabel}>Their Genres</span>
            <div className={styles.tagContainer}>
            {node.artistData.genre.map((genre, index) => (
            <span key={index} className={styles.tag}>{genre}</span>
          ))}
        </div>
          </div>
          <div className={styles.infoItem}>
          <span className={styles.infoLabel}>🌟 Unique Selling Points</span>
          <span className={styles.infoValue}>
            {Object.entries(node.artistData.usp).map(([key, value]) => (
            <div key={key}>
              <strong>{key}:</strong> {value}
            </div>
            ))}
          </span>
          </div>
          <div className={styles.infoItem}>
          <span className={styles.infoLabel}>🎨 Key Talents</span>
          <span className={styles.infoValue}>
            {Object.entries(node.artistData.keyTalents).map(([key, value]) => (
            <div key={key}>
              <strong>{key}:</strong> {value}
            </div>
            ))}
          </span>
          </div>
          <div className={styles.infoItem}>
          <span className={styles.infoLabel}>💖 Core Values</span>
          <span className={styles.infoValue}>
            {Object.entries(node.artistData.coreValues).map(([key, value]) => (
            <div key={key}>
              <strong>{key}:</strong> {value}
            </div>
            ))}
          </span>
          </div>
        </div>
        )}
        {node.type === 'current_audience' && (
        <div className={styles.currentAudienceInfo}>
          <div className={styles.infoItem}>
          <span className={styles.infoLabel}>🥸 Age Range</span>
          <span className={styles.infoValue} style={{textAlign: 'center'}}>{node.attributes.data.age}</span>
          <span className={styles.infoLabel}>📝 Key Terms</span>
          <span className={styles.infoValue}><strong>Interests:</strong> {node.attributes.data.interest.join(', ')} </span>
          <span className={styles.infoValue}><strong>Values:</strong> {node.attributes.data.values.join(', ')} </span>
          </div>
          
        </div>
        )}
        {node.type === 'potential_audience' && (
        <div className={styles.potentialAudienceInfo}>
          <div className={styles.infoItem}>
          <span className={styles.infoLabel}>🧐 Who are they? </span>
          <span className={styles.infoValue}>{node.potentialAudienceData.promise}</span>
          </div>
          <div className={styles.infoItem}>
          <span className={styles.infoLabel}>🤔 The best approach?</span>
          <span className={styles.infoValue}>{node.potentialAudienceData.approach}</span>
          </div>
          <div className={styles.infoItem}>
          <span className={styles.infoLabel}>😯 But beware! </span>
          <span className={styles.infoValue}>{node.potentialAudienceData.drawback}</span>
          </div>
        </div>
        )}
        {node.type === 'content_action' && (
        <div className={styles.contentActionInfo}>
          <div className={styles.infoItem}>
          <span className={styles.infoLabel}>🤷‍♂️ What could they do?</span>
          <span className={styles.infoValue}>{node.attributes.data.brief_description}</span>
          </div>
          <div className={styles.infoItem}>
          <span className={styles.infoLabel}>💃 What would be the results?</span>
          <span className={styles.infoValue}>{node.attributes.data.expected_outcome}</span>
          </div>
        </div>
        )}
        {node.type === 'brand_perception' && (
        <div className={styles.brandPerceptionInfo}>
          <div className={styles.infoItem}>
          <span className={styles.infoLabel}>🎯 What does the content align with?</span>
          <span className={styles.infoValue}>{node.attributes.data.alignment_explanation}</span>
          </div>
          <div className={styles.infoItem}>
          <span className={styles.infoLabel}>✨ The positive impact</span>
          <span className={styles.infoValue}>{node.attributes.data.positive_impact}</span>
          </div>
          <div className={styles.infoItem}>
          <span className={styles.infoLabel}>🧐 but be aware</span>
          <span className={styles.infoValue}>{node.attributes.data.negative_impact}</span>
          </div>
          <div className={styles.infoItem}>
          <span className={styles.infoLabel}>🤾‍♀️ How to make it stick</span>
          <ul className={styles.infoValue}>
            {node.attributes.data.optimization_suggestions.map((suggestion, index) => (
            <li key={index}>{suggestion}</li>
            ))}
          </ul>
          </div>
        </div>
        )}
      </div>
      <div className={styles.nodeInfoActions}>
        <button className={styles.takeNote} onClick={() => handleNodeClick(node)}>📝 Take Note</button>
      </div>
      </div>
    );
  }, [focusNode, handleNodeClick]);



useEffect(() => {
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { obfuscatedKey, timestamp } = getObfuscatedKeyWithTimestamp();
      const response = await fetch(
        `${process.env.REACT_APP_API}/artist/audience?artist_name=${artistName}`,
        {
          headers: {
            'API-Key': obfuscatedKey,
            'timestamp': timestamp
          }
        }
      );
      if (response.status === 404) {
        setTimeout(() => {
          onBack();
        }, 3000);
        throw new Error('Artist not found! Redirecting back to search...');
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setFullGraphData(data.network_data);
      
      const artistNode = data.network_data.nodes.find(n => n.type === 'artist');
      if (artistNode) {
        setSelectedNode(artistNode);
        navigationHistoryRef.current = [artistNode];
        setnavigationHistory([artistNode]);
        updateVisibleGraph(artistNode, data.network_data);
        setCurrentView(getViewInfo(artistNode.type, artistNode.name));
        setDisplayedNode(artistNode);

        setTimeout(() => {
          if (graphRef.current) {
            const graph = graphRef.current;
            const centerX = window.innerWidth / 2;
            const centerY = (window.innerHeight / 2) + (window.innerHeight * 0.2);
            graph.centerAt(centerX, centerY, 0);

            if (isMobile) {
              graph.zoom(12, 0);
              
              setTimeout(() => {
                graph.zoomToFit(1000, 50, node => {
                  return (
                    node.type === NAVIGATION_LEVELS.ARTIST ||
                    node.type === NAVIGATION_LEVELS.CURRENT_AUDIENCE
                  );
                });
              }, 100);
            } else {
              setTimeout(() => {
                graph.zoomToFit(1000, 200, node => {
                  return (
                    node.type === NAVIGATION_LEVELS.ARTIST ||
                    node.type === NAVIGATION_LEVELS.CURRENT_AUDIENCE
                  );
                });
              }, 300);
            }
          }
        }, 500);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (artistName) {
    fetchData();
  }
}, [artistName, updateVisibleGraph, onBack]);

  // Export functionality
  const handleExport = useCallback(() => {
    const exportData = {
      artist: artistName,
      timestamp: new Date().toISOString(),
      selectedStrategy: selectedNode?.type === 'content_action' ? selectedNode : null,
      brandImpact: selectedNode?.type === 'brand_perception' ? selectedNode : null,
      navigationPath: navigationHistory.map(node => ({
        type: node.type,
        name: node.name,
        id: node.id
      }))
    };

    const blob = new Blob([JSON.stringify(exportData, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${artistName}-audience-strategy-${new Date().toISOString()}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }, [artistName, selectedNode, navigationHistory]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (infoBoxRef.current && !infoBoxRef.current.contains(event.target)) {
        setSelectedNode(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  


  useEffect(() => {
    if (!graphRef.current || !visibleGraphData) return;

    const forceGraph = graphRef.current;


    forceGraph.d3Force('link')
      .distance(DEFAULT_LINK_DISTANCE)
      .strength(0.001);


    forceGraph.d3Force('charge')
      .strength(-10000)
      .distanceMax(isMobile ? 500 : 1000)
      .distanceMin(500);


    forceGraph.d3Force('radial', d3.forceRadial(
      node => {
        switch(node.type) {
          case 'artist': return 0;
          case 'current_audience': return 300;
          case 'potential_audience': return 600;
          case 'content_action': return 900;
          case 'brand_perception': return 1200;
          default: return 300;
        }
      },
      window.innerWidth / 2,
      window.innerHeight / 2
    ).strength(0.3));


    forceGraph.d3Force('collision', d3.forceCollide()
      .radius(node => NODE_SIZES[node.type] * 5)
      .strength(1)
    );

    forceGraph.d3Force('spacing', alpha => {
      const minDistance = isMobile ? 100 : 200;
      visibleGraphData.nodes.forEach(node1 => {
        visibleGraphData.nodes.forEach(node2 => {
          if (node1 !== node2) {
            const dx = node2.x - node1.x;
            const dy = node2.y - node1.y;
            const distance = Math.sqrt(dx * dx + dy * dy);
            if (distance < minDistance) {
              const factor = (minDistance - distance) / distance * alpha;
              const adjustX = dx * factor;
              const adjustY = dy * factor;
              if (node1.vx !== undefined && node1.vy !== undefined) {
                node1.vx -= adjustX;
                node1.vy -= adjustY;
              }
              if (node2.vx !== undefined && node2.vy !== undefined) {
                node2.vx += adjustX;
                node2.vy += adjustY;
              }
            }
          }
        });
      });
    });


    forceGraph.d3Force('center', d3.forceCenter(
      window.innerWidth / 2,
      window.innerHeight / 2
    ).strength(0.1));


    forceGraph.d3ReheatSimulation();
  }, [visibleGraphData]);



  const renderBreadcrumbs = useCallback(() => {
    return (
      <div className={styles.breadcrumbs}>
        {navigationHistory.map((node, index) => (
          <React.Fragment key={node.id}>
            {index > 0 && <span className={styles.breadcrumbSeparator}>/</span>}
            <button
              className={`${styles.breadcrumbButton} ${index === navigationHistory.length - 1 ? styles.current : ''}`}
              onClick={() => {
                const newHistory = navigationHistory.slice(0, index + 1);
                navigationHistoryRef.current = newHistory;
                setnavigationHistory(newHistory);
                setSelectedNode(node);
                updateVisibleGraph(node, fullGraphData, true);
              }}
            >
              {node.name.replace(/_/g, ' ')
                      .toLowerCase()
                      .split(' ')
                      .map((word, index) => index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word)
                      .join(' ')}
            </button>
          </React.Fragment>
        ))}
      </div>
    );
  }, [navigationHistory, fullGraphData, updateVisibleGraph]);
  
  

  const renderHelp = useCallback(() => {
    if (!showHelp) return null;
  
    const nodeTypes = {
      artist: {
        emoji: '💃',
        color: NODE_COLORS.artist,
        title: 'ARTIST',
        description: 'This is the artists 🎶 persona, it all starts from here! '
      },
      current_audience: {
        emoji: '👥',
        color: NODE_COLORS.current_audience,
        title: 'CURRENT AUDIENCE',
        description: 'This was the original audience of the artist we found on the platform, 🧐 who else might like them?'
      },
      potential_audience: {
        emoji: '🎯',
        color: NODE_COLORS.potential_audience,
        title: 'POTENTIAL AUDIENCE',
        description: 'These are the fresh audience fans that they have not reached yet, 🤔 what will resonate with them?'
      },
      content_action: {
        emoji: '📝',
        color: NODE_COLORS.content_action,
        title: 'CONTENT ACTION',
        description: 'Just knowing the audience is not enough 🤷‍♂️, what content will resonate with them? '
      },
      brand_perception: {
        emoji: '💫',
        color: NODE_COLORS.brand_perception,
        title: 'BRAND PERCEPTION',
        description: 'It all comes down to this 🕵️‍♀️, what will be the impact on the brand perception?'
      }
    };

    return (
      <div className={styles.helpOverlay}>
        <div className={styles.helpContent}>
          <h2>🧐 What does it all mean?</h2>
          <div className={styles.helpGrid}>
            {Object.entries(nodeTypes).map(([type, info]) => (
              <div key={type} className={styles.helpItem}>
                <div className={styles.iconContainer}>
                  <div 
                    className={styles.helpIcon} 
                    style={{
                      background: info.color,
                      color: '#FFFFFF'
                    }}
                  >
                     {info.emoji}
                    <h3 className={styles.helpLabel}>{info.title} </h3>
                    
                  </div>
                </div>
                <div>
                  <p>{info.description}</p>
                </div>
              </div>
            ))}
          </div>
          <button className={styles.closeHelp} onClick={() => setShowHelp(false)}>
            Got it!
          </button>
        </div>
      </div>
    );
  }, [showHelp]);

  
  if (isLoading) {
    return (
      <div className={styles.loadingOverlay}>
        <div className={styles.loadingSpinner} />
        <p>🧐 Loading audience insights...</p>
      </div>
    );
  }

  if (error && error.includes('Artist not found')) {
    return (
      <div className={styles.notFoundOverlay}>
        <div className={styles.notFoundContent}>
          <div className={styles.notFoundIcon}>🎵</div>
          <h2>Artist Not Found</h2>
          <p>We couldn't find any data for this artist</p>
          <div className={styles.redirectMessage}>
            Redirecting back to search...
            <div className={styles.loadingDots}>
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          </div>
        </div>
      </div>
    );
  }  


  

  if (error) {
    return (
      <div className={styles.errorOverlay}>
        <div className={styles.errorContent}>
          <h2> 😯 Oops! Something went wrong</h2>
          <p>{error}</p>
          <button onClick={() => window.location.reload()}>
            Try Again
          </button>

        </div>
      </div>
    );
  }




  return (
    <div className={styles.audienceFinderFullscreen}>
      <header className={styles.header}>
        <div className={styles.headerLeft}>
        <button className={styles.homeButton} onClick={onBack}>
        <House className={styles.icon}/>
        <span>Home</span>
        </button>
        <button className={styles.dashboardButton} onClick={onBackToDashboard}>
        <LayoutDashboard className={styles.icon}/>  
        <span>Artist Profile</span>
        </button>

        </div>
        
        <div className={styles.headerCenter}>
          <h1 className={styles.title}>{currentView.title}</h1>
          <p className={styles.description}>{currentView.description}</p>
          {renderBreadcrumbs()}
        </div>

        <div className={styles.headerRight}>
          <button className={styles.helpButton} onClick={() => setShowHelp(true)}><Info className={styles.icon} /></button>
        </div>
      </header>

      <main className={styles.mainContent}>
      <ForceGraph2D
      ref={graphRef}
      graphData={visibleGraphData}
      nodeLabel={node => node.name.replace(/_/g, ' ').toLowerCase().split(' ').map((word, index) => index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word).join(' ')}
      nodeColor={node => NODE_COLORS[node.type]}
      nodeVal={node => NODE_SIZES[node.type]}
      linkColor={link => {
        const sourceNode = visibleGraphData.nodes.find(n => 
          n.id === (link.source.id || link.source)
        );
        return sourceNode ? `${NODE_COLORS[sourceNode.type]}` : '#999';
      }}
      linkWidth={4}
      linkOpacity={10}
      linkDirectionalParticles={4}
      linkDirectionalParticleWidth={3}
      linkDirectionalParticleSpeed={d => (d.value || 1) * 0.002}
      onNodeHover={!isMobile ? handleNodeHover : null}
      onNodeClick={!isMobile ? handleNodeClick : null}
      {...(isMobile ? {
        onNodeDragStart: (node) => {
          const startTime = new Date().getTime();
          setTouchStartTime(startTime);
          setIsTouching(true);
      
          const timeout = setTimeout(() => {
            if (isTouching) {
              setDisplayedNode(node);
              setIsMobileNodeInfoOpen(true);
            }
          }, 200);
      
          setTouchTimeout(timeout);
        },
        onNodeDrag: (node) => {
          if (isTouching) {
            setIsTouching(false);
            if (touchTimeout) {
              clearTimeout(touchTimeout);
            }
          }
        },
        onNodeDragEnd: (node) => {
          const endTime = new Date().getTime();
          const touchDuration = endTime - touchStartTime;
          setIsTouching(false);
      
          if (touchTimeout) {
            clearTimeout(touchTimeout);
          }
      
          handleNodeClick(node);
        },
        onClick: (node) => {
          if (node) {
            handleNodeClick(node);
          }
        }
      } : {})}
      nodeCanvasObject={(node, ctx, globalScale) => {
        if (!node.x || !node.y || !isFinite(node.x) || !isFinite(node.y)) return;
      
        const size = NODE_SIZES[node.type] || 20;
        const label = node.name.replace(/_/g, ' ').toLowerCase().split(' ').map((word, index) => index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word).join(' ');
        
        const fontSize = isMobile ? 
          Math.max(12, size/3) / globalScale :
          Math.max(14, size/3) / globalScale;

        ctx.beginPath();
        ctx.arc(node.x, node.y, size, 0, 2 * Math.PI, false);

        const gradient = ctx.createRadialGradient(
          node.x - size/3,
          node.y - size/3,
          0,
          node.x,
          node.y,
          size
        );
        
        const nodeColor = NODE_COLORS[node.type];
        gradient.addColorStop(0, nodeColor);
        gradient.addColorStop(1, d3.color(nodeColor).darker(0.5));
        
        ctx.fillStyle = gradient;
        ctx.fill();

        // Add touch feedback glow for mobile
        if (isMobile && isTouching && node === displayedNode) {
          ctx.save();
          ctx.shadowColor = nodeColor;
          ctx.shadowBlur = 20;
          ctx.strokeStyle = 'white';
          ctx.lineWidth = 3;
          ctx.stroke();
          ctx.restore();
        }

        ctx.strokeStyle = d3.color(nodeColor).brighter(0.5);
        ctx.lineWidth = 2;
        ctx.stroke();

        const labelY = node.y + size + fontSize/2;

        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.font = `${fontSize}px Inter`;
        
        if (isMobile) {
          ctx.shadowColor = 'rgba(0, 0, 0, 0.8)';
          ctx.shadowBlur = 6;
        } else {
          ctx.shadowColor = 'rgba(0, 0, 0, 0.6)';
          ctx.shadowBlur = 4;
        }
        ctx.shadowOffsetX = 2;
        ctx.shadowOffsetY = 2;
        
        ctx.fillStyle = '#FFFFFF';
        ctx.fillText(label, node.x, labelY);
        
        ctx.shadowColor = 'transparent';
        ctx.shadowBlur = 0;
        
        const textWidth = ctx.measureText(label).width;
        node.__bckgDimensions = {
          width: textWidth,
          height: fontSize,
          x: node.x - textWidth/2,
          y: labelY - fontSize/2
        };
      }}
      nodePointerAreaPaint={(node, color, ctx) => {
        if (!node.x || !node.y || !isFinite(node.x) || !isFinite(node.y)) return;
        
        const size = NODE_SIZES[node.type] || 20;
        const touchSize = isMobile ? size * 1.5 : size;
        
        ctx.beginPath();
        ctx.arc(node.x, node.y, touchSize, 0, 2 * Math.PI, false);
        ctx.fillStyle = color;
        ctx.fill();
        
        const dims = node.__bckgDimensions;
        if (dims) {
          ctx.fillStyle = color;
          ctx.fillRect(dims.x, dims.y, dims.width, dims.height);
        }
      }}
    />

        {displayedNode && !isMobileNodeInfoOpen && (
          <div
            ref={infoBoxRef}
            className={`${styles.hoverInfo} ${styles.selectedInfo}`}
          >
            {renderNodeInfo(displayedNode)}
          </div>
        )}
      </main>
      <button 
            className={styles.backButton} 
            onClick={() => handleNavigation('back')}
            disabled={navigationHistory.length <= 1}
          ><Undo2 className={styles.icon} />
          </button>
      {renderHelp()}
      {!isMobile && displayedNode && !isMobileNodeInfoOpen && (
  <div
    ref={infoBoxRef}
    className={`${styles.hoverInfo} ${styles.selectedInfo}`}
  >
    {renderNodeInfo(displayedNode)}
  </div>
)}
<AnimatePresence>
  {isMobile && isMobileNodeInfoOpen && displayedNode && (
    <motion.div
      className={styles.mobileNodeInfoOverlay}
      initial={{ y: "100%" }}
      animate={{ y: "90%" }}
      exit={{ y: "100%" }}
      transition={{ type: "spring", damping: 25 }}
      drag="y"
      dragConstraints={{ top: 0, bottom: 0 }}
      dragElastic={0.2}
      onDragEnd={(event, info) => {
        if (info.offset.y > 50) {
          handleMobileNodeInfoClose();
        } else if (info.offset.y < -20) {
          // Expand to show more content
          event.target.style.transform = 'translateY(0%)'; // Fully expanded
        } else {
          // Return to peek state
          event.target.style.transform = 'translateY(90%)';
        }
      }}
      onClick={(event) => {
        // Toggle between peek and full view
        const currentTransform = event.currentTarget.style.transform;
        if (currentTransform.includes('90%')) {
          event.currentTarget.style.transform = 'translateY(0%)';
        }
      }}
    >
      <div className={styles.mobileNodeInfo}>
        <div className={styles.dragIndicator} />
        <div className={styles.closeButton} onClick={(e) => {
          e.stopPropagation();
          handleMobileNodeInfoClose();
        }}>
          ✕
        </div>
        <div className={styles.mobileNodeInfoContent}>
          {renderNodeInfo(displayedNode)}
        </div>
      </div>
    </motion.div>
  )}
</AnimatePresence>
      <button 
            className={styles.exportButton} 
            onClick={handleExport}
            disabled={!selectedNode || !['content_action', 'brand_perception'].includes(selectedNode.type)}
          >
            <Download className={styles.icon} />
            Export Strategy
      </button>
    </div>

    
  );
};

export default AudienceFinder;

// if you are reading this, you are awesome! ✨
// Written with love by @hook12aaa