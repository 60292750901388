// LocationPicker.js
import React, { useState, useEffect, useCallback } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { X } from 'lucide-react';
import axios from 'axios';
import { getObfuscatedKeyWithTimestamp } from '../../services/policy_delta';
import styles from './LocationPicker.module.css';
import ThreeJSOverlay from './ThreeJSOverlay';

const LocationPicker = ({ onLocationSelect, onClose }) => {
  const [venues, setVenues] = useState([]);
  const [mapCenter, setMapCenter] = useState([54.5, -2]); // UK center
  const [mapZoom, setMapZoom] = useState(6);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [maxVenueCount, setMaxVenueCount] = useState(0);

  useEffect(() => {
    const fetchVenues = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const { obfuscatedKey, timestamp } = getObfuscatedKeyWithTimestamp();
        const response = await axios({
          method: 'GET',
          url: `${process.env.REACT_APP_API}/artist/location`,
          headers: {
            'API-Key': obfuscatedKey,
            'timestamp': timestamp,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        });

        if (response.data) {
          const validVenues = response.data.filter(venue => 
            venue.GPS && 
            typeof venue.GPS.lat === 'number' && 
            typeof venue.GPS.lon === 'number'
          );
          
          setVenues(validVenues);

          const maxCount = Math.max(...Object.values(
            validVenues.reduce((acc, venue) => {
              const key = `${venue.GPS.lat.toFixed(2)},${venue.GPS.lon.toFixed(2)}`;
              acc[key] = (acc[key] || 0) + 1;
              return acc;
            }, {})
          ));
          setMaxVenueCount(maxCount);
        }
      } catch (error) {
        console.error('Error fetching venues:', error);
        setError(error.response?.data?.error || 'Failed to load venues. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchVenues();
  }, []);

  const handleLocationSelect = useCallback((location) => {
    onLocationSelect(location);
    onClose();
  }, [onLocationSelect, onClose]);

  return (
    <div className={styles.locationPicker}>
      <div className={styles.header}>
        <h2 className={styles.title}>Select a Location</h2>
        <button className={styles.closeButton} onClick={onClose}>
          <X size={24} />
        </button>
      </div>
      <div className={styles.mapContainer}>
        <MapContainer
          center={mapCenter}
          zoom={mapZoom}
          className={styles.map}
          zoomControl={false}
          scrollWheelZoom={true}
        >
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          />
          <ThreeJSOverlay
            venues={venues}
            maxCount={maxVenueCount}
            onLocationSelect={handleLocationSelect}
          />
        </MapContainer>
      </div>
      {isLoading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loader} />
          <span>Loading venues...</span>
        </div>
      )}
      {error && (
        <div className={styles.errorOverlay}>
          <div className={styles.errorContent}>
            <p>{error}</p>
            <button onClick={() => setError(null)}>Try Again</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationPicker;