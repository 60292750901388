import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {track } from '@vercel/analytics';
import { MapPin, Users, LayoutDashboard } from 'lucide-react';
import '../src/css/app.css';

// Component imports
import AboutPage from './about';
import KnowledgeGraphBackground from './utils/KnowledgeGraphBackground';
import ArtistDashboard from './components/dashboard/ArtistDashboard';
import VenueFinder from './components/VenueFinder/VenueFinder';
import AudienceFinder from './components/AudienceFinder/AudienceFinder';
import IntroAnimation from './utils/IntroAnimation';
import AddArtist from './components/AddArtist/AddArtist';

// Asset imports
import Synspire_logo from './images/Synspire_logo.png';
import { getObfuscatedKeyWithTimestamp } from './services/policy_delta';

// Constants
const SCREENS = {
  HOME: 'home',
  ABOUT: 'about',
  ADD_ARTIST: 'addArtist',
  DASHBOARD: 'dashboard',
  VENUE_FINDER: 'venueFinder',
  AUDIENCE_FINDER: 'audienceFinder'
};

const SEARCH_MODES = {
  DASHBOARD: 'dashboard',
  VENUE: 'venue',
  AUDIENCE: 'audience'
};

const MODES = [
  { id: SEARCH_MODES.DASHBOARD, icon: LayoutDashboard, label: 'Search Profiles' },
  { id: SEARCH_MODES.VENUE, icon: MapPin, label: 'Find Venues' },
  { id: SEARCH_MODES.AUDIENCE, icon: Users, label: 'Grow Audiences' }
];

function App() {
  const [appState, setAppState] = useState({
    activeScreen: SCREENS.HOME,
    searchMode: SEARCH_MODES.DASHBOARD,
    searchQuery: '',
    artistName: '',
    isTransitioning: false,
    showIntro: true,
    artistSuggestions: [],
    currentSuggestion: '',
    artistNamesList: [],
    featureHints: { venue: false, audience: false }
  });

  useEffect(() => {
    const handlePopState = (event) => {
      const url = new URL(window.location.href);
      const artistName = url.searchParams.get('artist_name');
  
      let screen = SCREENS.HOME;
      if (url.pathname === '/') {
        screen = SCREENS.HOME;
      } else if (url.pathname === '/venues') {
        screen = SCREENS.VENUE_FINDER;
      } else if (url.pathname === '/audience') {
        screen = SCREENS.AUDIENCE_FINDER;
      } else if (url.pathname === '/profile') {
        screen = SCREENS.DASHBOARD;
      } else if (url.pathname === '/about') {
        screen = SCREENS.ABOUT;
      } else if (url.pathname === '/addArtist') {
        screen = SCREENS.ADD_ARTIST;
      }
  
      setAppState(prevState => {
        const newState = {
          ...prevState,
          activeScreen: screen,
          artistName: artistName || '',
          searchMode: prevState.searchMode,
          searchQuery: artistName || ''
        };
  
        console.log('New App State:', newState);
        return newState;
      });
    };
  
    window.addEventListener('popstate', handlePopState);
  
    return () => window.removeEventListener('popstate', handlePopState);
  }, []);


  const handleSuggestionClick = (suggestion) => {
    track('search', { 
      search_query: suggestion, 
      search_mode: appState.searchMode, 
      method: 'suggestion' 
    });

    const screenMap = {
      [SEARCH_MODES.VENUE]: SCREENS.VENUE_FINDER,
      [SEARCH_MODES.AUDIENCE]: SCREENS.AUDIENCE_FINDER,
      [SEARCH_MODES.DASHBOARD]: SCREENS.DASHBOARD
    };

    navigateToScreen(screenMap[appState.searchMode], { artistName: suggestion });
  };

  const handleInspire = () => {
    const randomIndex = Math.floor(Math.random() * appState.artistNamesList.length);
    const randomArtist = appState.artistNamesList[randomIndex];

    setAppState(prev => ({
      ...prev,
      searchQuery: randomArtist,
      currentSuggestion: ''
    }));

    searchInputRef.current?.focus();
    track('page_view', { pageview: 'inspire' });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Tab' && appState.currentSuggestion) {
      e.preventDefault();
      setAppState(prev => ({
        ...prev,
        searchQuery: prev.currentSuggestion,
        currentSuggestion: ''
      }));
    } else if (e.key === 'Enter') {
      handleSearch(e);
    }
  };

  // Refs
  const graphRef = useRef(null);
  const searchInputRef = useRef(null);

  // URL and initial state management
  useEffect(() => {
    document.title = "Synspire";
    const params = new URLSearchParams(window.location.search);
    
    if (window.location.pathname === '/' && !params.has('artist_name')) {
      setAppState(prev => ({ 
        ...prev,
        history: [{
          timestamp: new Date().toISOString(),
          query: '',
          screen: SCREENS.HOME
        }, ...(prev.history || [])]
      }));
    } else if (params.has('artist_name')) {
      const artistName = params.get('artist_name');
      setAppState(prev => ({ ...prev, artistName }));
      navigateToScreen(SCREENS.DASHBOARD, { artistName });
    }
  }, []);

  // Responsive search placeholder
  useEffect(() => {
    const handleResize = () => {
      if (searchInputRef.current) {
        const width = window.innerWidth;
        searchInputRef.current.placeholder = width <= 600 
          ? "Search artist..." 
          : width <= 1000 
            ? "Enter an artist name" 
            : "Looking for an artist? Enter their name here";
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Initialize graph reference
  useEffect(() => {
    if (!graphRef.current) {
      graphRef.current = <KnowledgeGraphBackground />;
    }
  }, []);

  // API Calls
  const fetchArtistNames = async () => {
    try {
      const { obfuscatedKey, timestamp } = getObfuscatedKeyWithTimestamp();
      const response = await axios.get(`${process.env.REACT_APP_API}/api/artist/ids`, {
        headers: {
          'API-Key': obfuscatedKey,
          'timestamp': timestamp,
        }
      });
      setAppState(prev => ({
        ...prev,
        artistNamesList: response.data,
        artistSuggestions: response.data
      }));
    } catch (error) {
      console.error('Error fetching artist names:', error);
    }
  };

  useEffect(() => {
    fetchArtistNames();
  }, [appState.activeScreen]);

  const navigateToScreen = (screen, params = {}) => {
    const url = new URL(window.location);
  
    let path = '/';
    if (screen === SCREENS.VENUE_FINDER) {
      path = '/venues';
    } else if (screen === SCREENS.AUDIENCE_FINDER) {
      path = '/audience';
    } else if (screen === SCREENS.DASHBOARD) {
      path = '/profile';
    } else if (screen === SCREENS.ABOUT) {
      path = '/about';
    } else if (screen === SCREENS.ADD_ARTIST) {
      path = '/addArtist';
    }
  
    url.pathname = path;
  
    if (params.artistName) {
      url.searchParams.set('artist_name', params.artistName);
    } else {
      url.searchParams.delete('artist_name');
    }
  
    const newState = {
      screen,
      artistName: params.artistName || '',
      searchMode: params.searchMode || appState.searchMode,
      searchQuery: params.artistName || ''
    };
  
    window.history.pushState(newState, '', url.toString());
  
    setAppState(prev => ({
      ...prev,
      activeScreen: screen,
      artistName: params.artistName || '',
      searchQuery: params.artistName || '',
      searchMode: params.searchMode || prev.searchMode
    }));
  };

  // Event handlers
  const handleIntroComplete = () => {
    setAppState(prev => ({ ...prev, showIntro: false }));
    setTimeout(() => setAppState(prev => ({
      ...prev,
      featureHints: { ...prev.featureHints, venue: true }
    })), 500);
    setTimeout(() => setAppState(prev => ({
      ...prev,
      featureHints: { ...prev.featureHints, audience: true }
    })), 1000);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (appState.searchQuery.trim()) {
      track('search', { 
        search_query: appState.searchQuery, 
        search_mode: appState.searchMode, 
        method: 'search' 
      });

      const screenMap = {
        [SEARCH_MODES.VENUE]: SCREENS.VENUE_FINDER,
        [SEARCH_MODES.AUDIENCE]: SCREENS.AUDIENCE_FINDER,
        [SEARCH_MODES.DASHBOARD]: SCREENS.DASHBOARD
      };

      navigateToScreen(screenMap[appState.searchMode], { 
        artistName: appState.searchQuery 
      });
    }
  };

  const handleInputChange = (e) => {
    const query = e.target.value;
    const filteredSuggestions = appState.artistNamesList.filter(suggestion =>
      suggestion.toLowerCase().includes(query.toLowerCase()) && 
      query && suggestion[0].toLowerCase() === query[0].toLowerCase()
    );

    setAppState(prev => ({
      ...prev,
      searchQuery: query,
      artistSuggestions: filteredSuggestions
    }));
  };

  const renderSearchContent = () => {
    track('page_view', { pageview: 'home' });
    return (
      <div className={"contentclass"}>
        <div className="search-content fade-in">
          <img 
            src={Synspire_logo} 
            alt="Synspire Logo" 
            className="company_logo" 
          />
          <div className={`search-bar-container ${appState.featureHints.venue ? 'with-hints' : ''}`}>
            <div className="search-wrapper">
              <input
                ref={searchInputRef}
                type="text"
                placeholder="Looking for an artist? Enter their name here"
                className="search-bar"
                value={appState.searchQuery}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />
              {appState.artistSuggestions.length > 0 && appState.searchQuery.length > 0 && (
                <div className="suggestions-container">
                  {appState.artistSuggestions.map((suggestion, index) => (
                    <div
                      key={index}
                      className="suggestion-item"
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      {suggestion}...
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="search-mode-slider">
              {MODES.map((mode) => {
                const Icon = mode.icon;
                const isActive = appState.searchMode === mode.id;
                return (
                  <button
                    key={mode.id}
                    onClick={() => setAppState(prev => ({
                      ...prev,
                      searchMode: mode.id
                    }))}
                    className={`mode-button ${isActive ? 'active' : ''}`}
                  >
                    <Icon className="mode-icon" />
                    <span className="mode-label">{mode.label}</span>
                  </button>
                );
              })}
              <div className="mode-slider-background"></div>
            </div>
            <div className="search-bottom-buttons">
              <button className="inspire-button" onClick={handleInspire}>
                Inspire me
              </button>
              <button className="search-button" onClick={handleSearch}>
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAboutContent = () => {
    track('page_view', { pageview: 'about' });
    return (
      <div className={"contentclass"} style={{ 
        width: '80vw',
        height: '70vh',
        maxWidth: '1200px',
        margin: 'auto',
        overflow: 'hidden',
        position: 'relative',
      }}>
        <AboutPage />
      </div>
    );
  };

  const renderAddArtistContent = () => {
    track('page_view', { pageview: 'add' });
    return (
      <AddArtist onBackToHome={() => navigateToScreen(SCREENS.HOME)} />
    );
  };

  const renderContent = () => {
    if (appState.showIntro) {
      return <IntroAnimation onComplete={handleIntroComplete} />;
    }

    const contentMap = {
      [SCREENS.HOME]: renderSearchContent,
      [SCREENS.ABOUT]: renderAboutContent,
      [SCREENS.ADD_ARTIST]: renderAddArtistContent
    };

    return contentMap[appState.activeScreen]?.() || null;
  };

  // Main render
  return (
    <div className={`app-container ${appState.showIntro ? 'intro-mode' : ''}`}>
      {/* Navigation Bar */}
      <div className={`top-bar ${appState.showIntro ? 'hidden' : ''}`}>
        <div className="nav-buttons">
          <button className="button" onClick={() => navigateToScreen(SCREENS.HOME)}>
            Home
          </button>
          <button className="button" onClick={() => navigateToScreen(SCREENS.ABOUT)}>
            About
          </button>
          <button className="button" onClick={() => navigateToScreen(SCREENS.ADD_ARTIST)}>
          Add Artist
        </button>
        </div>
      </div>

      {graphRef.current}

      {/* Main Content */}
      <div className={`content ${appState.showIntro ? 'intro-active' : ''}`}>
        {renderContent()}
      </div>

      {/* Feature Components */}
      {appState.activeScreen === SCREENS.VENUE_FINDER && (
        <VenueFinder 
          artistName={appState.artistName} 
          range={3} 
          onBack={() => window.history.back()}
          onBackToDashboard={() => navigateToScreen(SCREENS.DASHBOARD, { artistName: appState.artistName })}
          onHome={() => navigateToScreen(SCREENS.HOME)}
        />
      )}

      {appState.activeScreen === SCREENS.AUDIENCE_FINDER && (
        <AudienceFinder 
          artistName={appState.artistName} 
          onBackToDashboard={() => navigateToScreen(SCREENS.DASHBOARD, { artistName: appState.artistName })}
          onBack={() => navigateToScreen(SCREENS.HOME , { artistName: ''})}
        />
      )}

      {appState.activeScreen === SCREENS.DASHBOARD && (
        <ArtistDashboard 
          artistName={appState.artistName} 
          onBackToSearch={() => navigateToScreen(SCREENS.HOME , { artistName: ''})}
          onShowVenueGraph={() => navigateToScreen(SCREENS.VENUE_FINDER, { artistName: appState.artistName })}
          onAudienceFinder={() => navigateToScreen(SCREENS.AUDIENCE_FINDER, { artistName: appState.artistName })}
        />
      )}
    </div>
  );
}

export default App;

// Congratulations! If you are reading this then it means you are definitely a curious person.
// We hire curious people. If you are interested in working with us, please reach out to us at
// contact@synspireforartists.com