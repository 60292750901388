import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { ForceGraph2D } from 'react-force-graph';
import axios from 'axios';
import { getObfuscatedKeyWithTimestamp } from '../../services/policy_delta';
import styles from './VenueFinder.module.css';
import LocationPicker from './LocationPicker';
import * as d3 from 'd3';
import { MapPin, LayoutDashboard, House, Pin } from 'lucide-react';

const NODE_COLORS = {
  'establish artistic identity': 'rgba(155,89,182,1)',  // Amethyst
  'showcase key talents': 'rgba(46,204,113,1)',         // Emerald
  'create memorable experiences': 'rgba(52,152,219,1)', // Peter River
  'connect with target audience': 'rgba(255,99,71,1)',  // Tomato
  'explore co-branding opportunities': 'rgba(241,196,15,1)' // Sun Flower
};

const TEXT_COLORS = {
  light: '#F0F4F8',
  dark: '#2D3748',
  warm: '#FDF2E9', 
  cool: '#E8F0FE', 
};


const VenueFinder = ({ artistName, onBack, onBackToDashboard, onHome }) => {
  const [graphData, setGraphData] = useState(null);
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [location, setLocation] = useState('London');
  const [searchLocation, setSearchLocation] = useState('London');
  const [selectedNode, setSelectedNode] = useState(null);
  const [hoveredNode, setHoveredNode] = useState(null);
  const [hoveredEdge, setHoveredEdge] = useState(null);
  const graphRef = useRef();
  const forceRef = useRef();
  const [notes, setNotes] = useState([]);
  const [selectedPurpose, setSelectedPurpose] = useState('all');
  const infoBoxRef = useRef(null);
  const [showLocationPicker, setShowLocationPicker] = useState(false);

  

  const getTextColor = (backgroundColor) => {
    const r = parseInt(backgroundColor.slice(4, backgroundColor.indexOf(',')));
    const g = parseInt(backgroundColor.slice(backgroundColor.indexOf(',') + 1, backgroundColor.lastIndexOf(',')));
    const b = parseInt(backgroundColor.slice(backgroundColor.lastIndexOf(',') + 1, -1));
    
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    
    if (luminance > 0.7) {
      return TEXT_COLORS.dark;
    } else if (luminance < 0.3) {
      return TEXT_COLORS.light;
    } else {
      const hue = d3.hsl(d3.rgb(r, g, b)).h;
      return (hue >= 30 && hue <= 210) ? TEXT_COLORS.cool : TEXT_COLORS.warm;
    }
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const { obfuscatedKey, timestamp } = getObfuscatedKeyWithTimestamp();
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API}/artist/recommendation`,
        params: {
          artist_id: artistName,
          location: searchLocation,
          radius: 50
        },
        headers: {
          'API-Key': obfuscatedKey,
          'timestamp': timestamp
        }
      });
      setGraphData(processGraphData(response.data));

    } catch (error) {
      console.error('Error caught:', error); 
      if (error.response && error.response.status === 404) {
        setTimeout(() => {
          onBack();
        }, 3000); 
        setError('Artist not found! Redirecting back to search...');
      } else {
        setError(" 🧐 Sorry, we haven't reached that location yet, try London!");
      }

    } finally {
      setIsLoading(false);
    }
  }, [artistName, searchLocation]);

  useEffect(() => {
    fetchData();

  const handleResize = () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [fetchData]);





  const handlePurposeChange = (e) => {
    setSelectedPurpose(e.target.value);
  };

  const handleTakeNote = (node) => {
    if (node.type === 'venue') {  
      const note = {
        venueName: node.name,
        location: `https://www.google.com/maps/search/?api=1&query=${node.GPS.lat},${node.GPS.lon}`,
      };
  
      setNotes([...notes, note]);
    }
  };
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (infoBoxRef.current && !infoBoxRef.current.contains(event.target)) {
        setSelectedNode(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const processGraphData = (data) => {
    const nodes = data.nodes.map(node => ({
      ...node,
      id: node.id,
      name: node.name,
      type: node.type,
      x: Math.random() * dimensions.width,
      y: Math.random() * dimensions.height,
      vx: 0,
      vy: 0
    }));

    const links = data.links.map(link => ({
      source: link.source,
      target: link.target,
      type: link.type,
      weight: link.weight
    }));

    nodes.forEach(node => {
      const connectedLinks = links.filter(link => link.source === node.id || link.target === node.id);
      const dominantEdge = connectedLinks.reduce((prev, current) => 
        (prev.weight > current.weight) ? prev : current
      );
      node.color = NODE_COLORS[dominantEdge.type] || 'rgba(22,160,133,1)'; 
    });

    return { nodes, links };
  };

  

  const initializeForceGraph = useCallback(() => {
    if (forceRef.current && graphData) {
      forceRef.current.d3Force('charge').strength(-500);
      forceRef.current.d3Force('link').distance(link => 200 + link.weight * 20).strength(0.2);
      forceRef.current.d3Force('collide', d3.forceCollide(100));
      forceRef.current.d3Force('center', d3.forceCenter());
      forceRef.current.d3Force('radial', null);


      forceRef.current.d3Force('link-force', (alpha) => {
        graphData.links.forEach(link => {
          const source = link.source;
          const target = link.target;
          let vx = target.x - source.x;
          let vy = target.y - source.y;
          const dist = Math.sqrt(vx * vx + vy * vy);
          vx /= dist;
          vy /= dist;
          target.vx -= vy * alpha;
          target.vy += vx * alpha;
          source.vx += vy * alpha;
          source.vy -= vx * alpha;
        });
      });
    }
  }, [graphData]);


  useEffect(() => {
    if (graphData) {
      initializeForceGraph();
    }
  }, [graphData, initializeForceGraph]);

  const handleLocationSearch = (e) => {
    e.preventDefault();
    setSearchLocation(location);
    fetchData();
  };

  const handleNodeHover = (node) => {
    setHoveredNode(node);
  };

  const handleLocationSelectVenues = useCallback((selectedLocation) => {
    setLocation(selectedLocation);
    setSearchLocation(selectedLocation);
    
    setShowLocationPicker(false);
    fetchData();
    console.log('Location selected:', selectedLocation);
  }, [fetchData]);
  

  const exportTourList = () => {
    const tourList = notes.map((note, index) => 
      `${index + 1}. ${note.venueName} located at ${note.location}`
    ).join('\n');
  
    const element = document.createElement('a');
    const file = new Blob([tourList], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = 'tour_list.txt';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };
  
  
  

  const renderNodeInfo = (node) => {
    if (!node) return null;
  
    if (node.type === 'artist') {
      return (
        <div className={styles.nodeInfo}>
          <h3>{node.name}</h3>
  
          <div className={styles.section}>
            <h4>🌟 Unique Selling Points</h4>
            <ul>
              {Object.entries(node.usp).map(([key, value]) => (
                <li key={key}><strong>{key}:</strong> {value}</li>
              ))}
            </ul>
          </div>
  
          <div className={styles.section}>
            <h4>💖 Core Values</h4>
            <ul>
              {Object.entries(node.core_values).map(([key, value]) => (
                <li key={key}><strong>{key}:</strong> {value}</li>
              ))}
            </ul>
          </div>
  
          <div className={styles.section}>
            <h4>🎨 Key Talents</h4>
            <ul>
              {Object.entries(node.keyTalents).map(([key, value]) => (
                <li key={key}><strong>{key}:</strong> {value}</li>
              ))}
            </ul>
          </div>
        </div>
      );
    }
  
    if (node.type === 'venue') {
      return (
        <div className={styles.nodeInfo}>
          <h3>{node.name}</h3>
          <h4>✏️ Description</h4>
          <p>{node.venue_description}</p>
  
          <div className={styles.section}>
            <h4>🙌 Unique Selling Points</h4>
            <ul>
              {Object.entries(node.usp).map(([key, value]) => (
                <li key={key}><strong>{key}:</strong> {value}</li>
              ))}
            </ul>
          </div>
  
          <div className={styles.section}>
            <h4>🤾‍♀️ Core Values</h4>
            <ul>
              {Object.entries(node.core_values).map(([key, value]) => (
                <li key={key}><strong>{key}:</strong> {value}</li>
              ))}
            </ul>
          </div>
  
          <div className={styles.section}>
            <h4>💃 Target Audiences</h4>
            {node.target_audience.map((audience, index) => (
              <div key={index} className={styles.audienceInfo}>
                <p><strong>Age:</strong> {audience.age}</p>
                <p><strong>Audience:</strong> {audience.audience}</p>
                <p><strong>Interests:</strong> {audience.interest.join(', ')}</p>
                <p><strong>Values:</strong> {audience.values.join(', ')}</p>
              </div>
            ))}
          </div>
  
          <div className={styles.section}>
            <h4>📞 Contact Details</h4>
            {node.contact ? (
              <>
                {node.contact.phone && <p><strong>Phone:</strong> {node.contact.phone}</p>}
                {node.contact.email && <p><strong>Email:</strong> {node.contact.email}</p>}
              </>
            ) : (
              <p>No contact details available</p>
            )}
          </div>
  
          {node.GPS && (
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${node.GPS.lat},${node.GPS.lon}`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.mapLink}
            >
              <MapPin size={16} /> View on Google Maps
            </a>
          )}
  
          <button
            className={styles.noteButton}
            onClick={() => handleTakeNote(node)}
          >
            📝 Take Note
          </button>
        </div>
      );
    }
  
    return null;
  };

  

  const handleNodeClick = useCallback((node) => {
    setSelectedNode(prevNode => prevNode === node ? null : node);
  }, []);



  const memoizedGraph = useMemo(() => (
    <ForceGraph2D
      ref={(el) => {
        graphRef.current = el;
        forceRef.current = el;
      }}
      graphData={graphData}
      width={dimensions.width}
      height={dimensions.height}
      nodeLabel="name"
      nodeColor={(node) => {
        if (selectedPurpose === 'all') {
          return node.type === 'artist' ? 'rgba(255, 215, 0, 1)' : node.color;
        } else {
          const connectedLinks = graphData.links.filter(
            (link) => link.source === node.id || link.target === node.id
          );
          const hasPurposeLink = connectedLinks.some((link) => link.type === selectedPurpose);
          return hasPurposeLink ? node.color : 'rgba(128, 128, 128, 0.2)';
        }
      }}
      linkColor={(link) => {
        if (selectedPurpose === 'all') {
          return NODE_COLORS[link.type] || 'rgba(22, 160, 133, 1)';
        } else {
          return link.type === selectedPurpose
            ? NODE_COLORS[link.type] || 'rgba(22, 160, 133, 1)'
            : 'rgba(128, 128, 128, 0.2)';
        }
      }}
      nodeCanvasObjectMode={() => 'after'}
      linkCanvasObjectMode={() => 'after'}
      nodeCanvasObject={(node, ctx, globalScale) => {
        const label = node.name;
        const fontSize = 14 / globalScale;
        ctx.font = `bold ${fontSize}px Inter, Arial, sans-serif`;
        const textWidth = ctx.measureText(label).width;
        const bckgDimensions = [textWidth, fontSize].map(n => n + fontSize * 0.2);
  
        // Node circle
        const nodeColor = node.type === 'artist' ? 'rgba(255, 215, 0, 1)' : node.color;
        ctx.fillStyle = nodeColor;
        ctx.beginPath();
        ctx.arc(node.x, node.y, 60, 0, 2 * Math.PI, false);
        ctx.fill();
  
        // Text
        const textColor = getTextColor(nodeColor);
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        
        // Text shadow for better visibility
        ctx.shadowColor = 'rgba(0, 0, 0, 0.3)';
        ctx.shadowBlur = 4;
        ctx.shadowOffsetX = 1;
        ctx.shadowOffsetY = 1;
        
        ctx.fillStyle = textColor;
        ctx.fillText(label, node.x, node.y);
        
        // Reset shadow
        ctx.shadowColor = 'transparent';
        ctx.shadowBlur = 0;
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
  
        node.__bckgDimensions = bckgDimensions;
      }}
      nodePointerAreaPaint={(node, color, ctx) => {
        ctx.fillStyle = color;
        const bckgDimensions = node.__bckgDimensions;
        bckgDimensions && ctx.fillRect(node.x - bckgDimensions[0] / 2, node.y - bckgDimensions[1] / 2, ...bckgDimensions);
      }}
      linkPointerAreaPaint={(link, color, ctx) => {
        const source = graphData.nodes.find(node => node.id === link.source);
        const target = graphData.nodes.find(node => node.id === link.target);
        
        if (!source || !target) {
          return null;
        }
        
        const radius = 60;
        
        if (Math.hypot(source.x - target.x, source.y - target.y) < radius * 2) {
          return null;
        }
        ctx.strokeStyle = color;
        ctx.lineWidth = 10;
        ctx.beginPath();
        ctx.moveTo(source.x, source.y);
        ctx.lineTo(target.x, target.y);
        ctx.stroke();
      }}
      linkWidth={link => 2}
      linkDirectionalParticles={2}
      linkDirectionalParticleWidth={link => (link.weight / 2) * 2}
      linkDirectionalParticleSpeed={link => link.weight * 0.0001}
      linkDirectionalParticleColor={link => NODE_COLORS[link.type] || 'rgba(22, 160, 133, 1)'}
      linkLabel={link => `${link.type}`}
      onNodeHover={handleNodeHover}
      onNodeClick={handleNodeClick}
      onLinkHover={(link) => {
        setHoveredEdge(link);
      }}
      onLinkClick={(link) => {
        setHoveredEdge(link);
      }}
      d3AlphaDecay={0.01}
      d3VelocityDecay={0.1}
      cooldownTicks={200}
      onEngineStop={() => {
        graphRef.current.zoomToFit(400, 100);
      }}
      enableNodeDrag={true}
      enableZoomPanInteraction={true}
      linkPositionUpdate={(link, { start, end }) => {
        const midPoint = {
          x: start.x + (end.x - start.x) / 2,
          y: start.y + (end.y - start.y) / 2
        };
        const dx = end.x - start.x;
        const dy = end.y - start.y;
        const length = Math.sqrt(dx * dx + dy * dy);
        const offset = Math.min(10, length / 5);
        const angle = Math.atan2(dy, dx) + Math.PI / 2;
        midPoint.x += Math.cos(angle) * offset;
        midPoint.y += Math.sin(angle) * offset;
        return [start, midPoint, end];
      }}
    />
  ), [graphData, dimensions, selectedNode, selectedPurpose, handleNodeHover, handleNodeClick]);


  if (error && error.includes('Artist not found')) {
    return (
      <div className={styles.notFoundOverlay}>
        <div className={styles.notFoundContent}>
          <div className={styles.notFoundIcon}>🎵</div>
          <h2>Artist Not Found</h2>
          <p>We couldn't find any data for this artist</p>
          <div className={styles.redirectMessage}>
            Redirecting back to search...
            <div className={styles.loadingDots}>
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          </div>
        </div>
      </div>
    );
  }  





  const renderLegend = () => (
    <div className={styles.legend}>
      <h4>🏃‍♀️‍➡️ Possible tour purposes</h4>
      {Object.entries(NODE_COLORS).map(([type, color]) => {
        let displayName;
        switch (type) {
          case 'establish artistic identity':
            displayName = '🎶 Showcase Artistic Identity';
            break;
          case 'showcase key talents':
            displayName = '💖 Play to your key Talents';
            break;
          case 'create memorable experiences':
            displayName = '💃 Create Unforgettable Experiences';
            break;
          case 'connect with target audience':
            displayName = '🫡 Engage Core Fanbase';
            break;
          case 'explore co-branding opportunities':
            displayName = '🤾‍♀️ Foster Brand Perception';
            break;
          default:
            displayName = type;
        }
        return (
          <div key={type} className={styles.legendItem}>
            <span className={styles.legendColor} style={{ backgroundColor: color }}></span>
            <span>{displayName}</span>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className={styles.venueFinderFullscreen}>
    <div className={styles.purposeFilter}>
      <select
        id="purposeSelect"
        value={selectedPurpose}
        onChange={handlePurposeChange}
      >
        <option value="all">💡 Show everything</option>
        <option value="establish artistic identity">🎶 Showcase Artistic Identity</option>
        <option value="showcase key talents">💖 Play to your key Talents</option>
        <option value="create memorable experiences">💃 Create Unforgettable Experiences</option>
        <option value="connect with target audience">🫡 Engage Core Fanbase</option>
        <option value="explore co-branding opportunities">🤾‍♀️ Foster Brand Perception</option>
      </select>
    </div>
      <button className={styles.backButton} onClick={onBackToDashboard}>
      <LayoutDashboard className={styles.icon} /> Artist Profile
      </button>
      <button className={styles.homeButton} onClick={onHome}>
        <House className={styles.icon}/> Home
      </button>

      <form onSubmit={handleLocationSearch} className={styles.searchForm}>
        <input
          type="text"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          placeholder="Enter location"
          className={styles.searchInput}
        />
        <button type="submit" className={styles.searchButton}>Search</button>
      </form>

      <button className={styles.locationPickerButton} onClick={() => setShowLocationPicker(true)}>
        <Pin className={styles.icon}/> Change Location
      </button>

      {showLocationPicker && (
        <LocationPicker 
          onLocationSelect={handleLocationSelectVenues}
          onClose={() => setShowLocationPicker(false)} 
        />
      )}

      <div className={styles.venueFinderContent}>
        {isLoading ? (
          <div className={styles.loadingContainer}>Loading...</div>
        ) : error ? (
          <div className={styles.errorContainer}><h2 className={styles.errorTitle}>{error}</h2></div>
        ) : graphData && graphData.nodes.length > 0 ? (
          <>
            {memoizedGraph}
            {renderLegend()}
            <button className={styles.exportButton}onClick={exportTourList}>
              📜 Export Tour List
            </button>

          </>
          
        ) :  (
          <div className={styles.noDataContainer}>
            <h2>Sorry, we haven't reached that location yet!</h2>
          </div>
        )}

      {(hoveredNode || selectedNode || hoveredEdge) && (
                <div
                  ref={infoBoxRef}
                  className={`${styles.hoverInfo} ${selectedNode ? styles.selectedInfo : ''}`}
                  onClick={(e) => {
                    if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'A') {
                      setSelectedNode(null);
                    }
                  }}
                >
                  {hoveredEdge ? (
                    <div className={styles.edgeInfo}>
                      <h3>{hoveredEdge.type}</h3>
                    </div>
                  ) : (
                    renderNodeInfo(selectedNode || hoveredNode)
                  )}
                </div>
        )}
      </div>
    </div>
  );
};

export default VenueFinder;

// Haha! what a mess! I'm not even sure what I'm looking at. I'm not sure if I should be proud or ashamed of this code. I'm just going to leave it here and move on.